import React from "react";
import {
  FULL_LOGO_IMAGE_PATH,
  FULL_LOGO_WHITE_PATH,
  WAREHOUSE_IMAGE_PATH,
} from "../../../constants/pathConstants";
import MessageBox from "../../Boxes/MessageBox/MessageBox";
import Input from "../../Input/Input";
import "./AuthLayout.css";

const AuthLayout = ({
  title,
  subtitle,
  errorMessage,
  children,
  inputTexts,
}) => {

  return (
    <>
      <div
        className="h-screen md:overflow-hidden md:grid md:grid-cols-7 lg:grid-cols-3"
        style={{
          background: "linear-gradient(85.6deg, #166BB8 2.03%, #3393E9 97.97%)",
        }}
      >
        <div className="hidden md:col-span-3 lg:col-span-1 md:flex md:flex-col md:items-center md:justify-evenly lg:justify-around md:relative">
          <div>
            <img
              src={FULL_LOGO_WHITE_PATH}
              alt={"corporate-white-logo"}
              className="md:w-72 lg:w-80 2xl:w-96"
            />
          </div>
          <div className="relative z-20 md:left-12 lg:left-16 xl:left-24 2xl:left-32">
            <img
              src={WAREHOUSE_IMAGE_PATH}
              alt={"banner-corporate"}
              className="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl"
            />
          </div>

          <div
            className="absolute z-10 hidden bg-transparent border-white rounded-full lg:block lg:-bottom-96 lg:-left-96 2xl:-bottom-80 2xl:-left-80 border-opacity-10"
            style={{ width: "48rem", height: "48rem", borderWidth: "6rem" }}
          />

          <div
            className="absolute z-10 hidden bg-transparent border-white rounded-full lg:block lg:-bottom-72 lg:-left-72 2xl:-bottom-56 2xl:-left-56 border-opacity-10"
            style={{ width: "32rem", height: "32rem", borderWidth: "6rem" }}
          />
        </div>
        <div className="flex flex-col items-center h-full p-4 overflow-auto bg-white justify-evenly md:col-span-4 lg:col-span-2 md:rounded-l-3xl md:justify-center ">
        {/* <div className="flex flex-col items-center justify-center h-screen bg-white md:col-span-4 lg:col-span-2 md:rounded-l-3xl"> */}
          <div className="py-4 md:hidden">
            <img
              src={FULL_LOGO_IMAGE_PATH}
              alt={"corporate-white-logo"}
              className="w-56"
            />
          </div>
          <div className="flex flex-col max-w-md p-5 md:p-10 md:mt-0 text-sm md:justify-center 2xl:py-[15vh] relative z-20">
            <h5 className="font-bold auth-layout__title">{title}</h5>
            <p className="auth-layout__subtitle">{subtitle}</p>
            {errorMessage ? (
              <MessageBox className={`mb-6`}>
                {errorMessage === "record not found"
                  ? "Nomor Telepon atau Sandi anda salah"
                  : errorMessage}
              </MessageBox>
            ) : (
              ""
            )}
            <div className="auth-layout__inputs">
              {inputTexts
                ? inputTexts.map((input, idx) => (
                    <Input
                      key={`input-${idx}`}
                      id={`input-${input.name}`}
                      label={input.label}
                      name={input.name}
                      type={input.type}
                      value={input.value}
                      placeholder={input.placeholder}
                      required={input.required}
                      onChange={input.onChange}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      autocomplete={input.autocomplete}
                      validation={input.validation}
                      validationColor={"red"}
                      mode={input.mode}
                    />
                  ))
                : ""}
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
