import React from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import Button from "../Button/Button";
import "./Pagination.css";

const Pagination = ({
  wrapperClassName,
  data,
  searchParams,
  setSearchParams,
  // page,
}) => {
  const CHECK_WRAPPER_CLASS_NAME = wrapperClassName || "";
  const { Page, TotalPage } = data || {};
  const firstPage = Number(Page) === 1;
  const lastPage = Number(Page) === Number(TotalPage);
  const totalPage = `${Page} dari ${TotalPage}`;

  const onClick = (type) => {
    const isFirst = type === "first";
    const isPrev = type === "prev";
    const isNext = type === "next";
    const isLast = type === "last";

    if (isNext) {
      if (lastPage) return;
      setSearchParams({ page: Number(Page) + 1 });
    }
    if (isPrev) {
      if (firstPage) return;
      setSearchParams({ page: Number(Page) - 1 });
    }

    if (isFirst) {
      if (firstPage) return;
      setSearchParams({ page: 1 });
    }
    if (isLast) {
      if (lastPage) return;
      setSearchParams({ page: Number(TotalPage) });
    }
  };

  const buttonProps = {
    buttonColor: "gray",
    buttonSize: "box",
    boxSize: 8,
    buttonType: "pagination",
  };

  const fadeChevron = {
    className: "opacity-50",
  };

  const totalDataInfo = (
    <div className="pagination__info">{`Showing ${data?.Records.length} of ${data?.TotalRecord} datas`}</div>
  );

  return (
    <div className={`pagination ${CHECK_WRAPPER_CLASS_NAME}`}>
      <div className={`pagination__nav `}>
        <Button {...buttonProps} onClick={() => onClick("first")}>
          <IoChevronBack />
          <IoChevronBack {...fadeChevron} />
        </Button>
        <Button {...buttonProps} onClick={() => onClick("prev")}>
          <IoChevronBack />
        </Button>
        <div>{Page && TotalPage === 0 ? "1 dari 1" : totalPage}</div>
        <Button {...buttonProps} onClick={() => onClick("next")}>
          <IoChevronForward />
        </Button>
        <Button {...buttonProps} onClick={() => onClick("last")}>
          <IoChevronForward {...fadeChevron} />
          <IoChevronForward />
        </Button>
      </div>
      {!data?.TotalRecord ? (
        <div className="pagination__info">{`Showing 0 of 0 datas`} </div>
      ) : (
        totalDataInfo
      )}
    </div>
  );
};

export default Pagination;
