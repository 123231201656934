import React, { forwardRef, useEffect, useState } from "react";
import {
  IoSearch,
  IoChevronDown,
  IoEyeOutline,
  IoPhonePortraitOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import "./Input.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import InputButton from "./InputButton/InputButton";
import InputAutoComplete from "./InputAutoComplete/InputAutoComplete";
import Button from "../Button/Button";

const Input = forwardRef(
  (
    {
      autoCompleteData = [],
      label,
      wrapperClassName,
      id,
      type = "text",
      mode,
      name,
      onChange,
      onFocus,
      onBlur,
      value,
      placeholder = "",
      autoComplete,
      required,
      variant,
      validation,
      validationColor,
      note,
      dateFormat,
      cols = "30",
      rows = "10",
      disabled,
      onClick,
      position,
      maxLength,
      minDate,
      buttonLoading,
      
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const [showPassword, setShowPassword] = useState(false);

    const validationColors = ["red", "white"];
    // const positions = ["left", "right"];
    // const sizes = ["sm", "md", "lg"];

    // const CHECK_POSITION = positions.find((x) => x === position)
    //   ? position
    //   : positions[0];

    const modes = [
      {
        name: "password",
        icon: (
          <IoEyeOutline
            className="input__icon input__icon--password"
            aria-label={showPassword}
            onClick={() => showPasswordHandler()}
          />
        ),
      },
      {
        name: "search",
        icon: <IoSearch className="input__icon input__icon--search" />,
      },
      {
        name: "select",
        icon: <IoChevronDown className="input__icon input__icon--select" />,
      },
      {
        name: "check",
        icon: (
          <Button
            className="input__icon input__icon--check"
            onClick={onClick}
            buttonColor={"transparent"}
            buttonLoading={buttonLoading}
          >
            <p className="input__icon--word">Check</p>
          </Button>
        ),
      },
      {
        name: "mass",
        icon: <span className="input__icon--mass">kg</span>,
      },
      {
        name: "currency",
        icon: <span className="input__icon--currency">Rp</span>,
      },
      {
        name: "phone",
        icon: (
          <IoPhonePortraitOutline className="input__icon input__icon--phone" />
        ),
      },
      {
        name: "lock-pass",
        icon: (
          <>
            <IoLockClosedOutline className="input__icon input__icon--lock"/>
            <IoEyeOutline
              className="input__icon input__icon--password"
              aria-label={showPassword}
              onClick={() => showPasswordHandler()}
            />
          </>
        ),
      },
    ];

    const CHECK_TYPE =
      type === "password" ? (showPassword ? "text" : "password") : type;
    const CHECK_VALIDATION = validation ? "input__field--error" : "";
    const CHECK_VALIDATION_COLOR = validationColors.find(
      (x) => x === validationColor
    )
      ? validationColor
      : validationColors[0];
    const CHECK_MODE = modes.find((x) => x.name === mode);

    const inputProps = {
      id: `${id}-field`,
      name: name,
      className: `input__field input__field--${
        CHECK_MODE?.name || "normal"
      } ${CHECK_VALIDATION}`,
      value: inputValue,
      type: CHECK_TYPE,
      placeholder: placeholder,
      autoComplete: autoComplete,
      maxLength: maxLength
    };

    const inputVariants = [
      {
        variant: "text",
        component: (
          <input
            {...inputProps}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e) => onChangeHandler(e)}
            disabled={disabled}
          />
        ),
      },
      {
        variant: "textarea",
        component: (
          <textarea
            name={name}
            cols={cols}
            rows={rows}
            className={`input__field input__field--textarea ${CHECK_VALIDATION}`}
            defaultValue={inputValue}
            onChange={(e) => onChangeHandler(e)}
            disabled={disabled}
          ></textarea>
        ),
      },
      {
        variant: "datePicker",
        component: (
          <DatePicker
            selected={value}
            onChange={(date) => onChange(date)}
            customInput={<InputButton validation={validation} />}
            dateFormat={dateFormat}
            minDate={minDate}
          />
        ),
      },
      {
        variant: "autoComplete",
        component: (
          <InputAutoComplete
            id={`${id}-field`}
            name={name}
            className={`input__field input__field--${
              CHECK_MODE?.name || "normal"
            } ${CHECK_VALIDATION}`}
            value={inputValue}
            type={CHECK_TYPE}
            placeholder={placeholder}
            autoComplete={autoComplete}
            autoCompleteData={autoCompleteData}
            onChange={() => {
              return { setInputValue, onChange };
            }}
          />
        ),
      },
    ];

    const CHECK_INPUT_VARIANT =
      inputVariants.find((x) => x.variant === variant)?.component ||
      inputVariants[0].component;

    const onChangeHandler = (e) => {
      // const targetValue = e.target.value;
      // setInputValue(targetValue);
      // onChange && onChange(targetValue, e);
      onChange && onChange(e);
    };

    const showPasswordHandler = () => {
      setShowPassword(showPassword ? false : true);
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <div id={id} className={wrapperClassName}>
        <label ref={ref} htmlFor={name} className={`input__wrapper`}>
          {label ? (
            <span className="input__label">
              {label}
              {required ? <span className="text-red-600">*</span> : ""}
            </span>
          ) : (
            ""
          )}
          <div className="input">
            {CHECK_INPUT_VARIANT}
            {CHECK_MODE?.icon}
          </div>
          {note ? <span className="note">{note}</span> : ""}
          {validation && typeof validation === "string" ? (
            <span
              className={`validation validation--${CHECK_VALIDATION_COLOR}`}
            >
              {validation}
            </span>
          ) : (
            ""
          )}
        </label>
      </div>
    );
  }
);

export default Input;
