export const NAME_VALIDATION = {
  message: {
    required: 'Nama lengkap harus diisi',
  }
}
export const USERNAME_VALIDATION = {
  message: {
    required: 'Username harus diisi',
  }
}
export const EMAIL_VALIDATION = {
  pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  message: {
    required: 'Email harus diisi',
    pattern: 'Silahkan masukan Email yang benar',
    invalid: 'Email tidak terdaftar'
  } 
}
export const PASSWORD_VALIDATION = {
  pattern : /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  message: {
    required: 'Kata Sandi harus diisi',
    pattern: 'Minimal 8 karakter, satu huruf kapital (A-Z), satu karakter khusus (!@#$), dan satu angka',
    invalid: 'Kata Sandi salah'
  }
}
export const CONFIRM_PASSWORD_VALIDATION = {
  message: {
    required: 'Konfirmasi Kata Sandi harus diisi',
    invalid: 'Kata Sandi tidak sama'
  }
}
export const GIRO_VALIDATION = {
  message: {
    required: 'Nomor Rekening Giro harus diisi',
    invalid: 'Nomor Rekening Giro tidak terdaftar'
  }
}
export const COMPANY_VALIDATION = {
  message: {
    required: 'Nama Perusahaan harus diisi',
    invalid: 'Perusahaan tidak terdaftar'
  }
}
export const COMPANY_ALIAS_VALIDATION = {
  message: {
    required: 'Alias Perusahaan harus diisi',
    invalid: 'Alias Perusahaan tidak terdaftar'
  }
}
export const COMPANY_ADDRESS_VALIDATION = {
  message: {
    required: 'Alamat Perusahaan harus diisi',
    invalid: 'Alamat Perusahaan tidak terdaftar'
  }
}
export const NIK_VALIDATION = {
  pattern: /^\d{16}$/,
  message: {
    required: 'NIK harus diisi',
    invalid: 'NIK harus 16 digit angka'
  }
}
export const ITEM_NAME_VALIDATION = {
  message: {
    required: 'Nama Produk harus diisi',
    invalid: 'Nama Produk tidak terdaftar'
  }
}
export const ITEM_VALUE_VALIDATION = {
  message: {
    required: 'Nilai Produk harus diisi',
  }
}
export const ITEM_PRICE_VALIDATION = {
  message: {
    required: 'Harga Produk harus diisi',
  }
}
export const ITEM_RECEIPT_DATE_VALIDATION = {
  message: {
    required: 'Tanggal Penerimaan Barang harus diisi',
    invalid: 'Tanggal Penerimaan Barang harus sebelum Tanggal Pengeluaran Barang'
  }
}
export const ITEM_RELEASED_DATE_VALIDATION = {
  message: {
    required: 'Tanggal Pengeluaran Barang harus diisi',
    invalid: 'Tanggal Pengeluaran Barang harus setelah Tanggal Penerimaan Barang'
  }
}
export const STORAGE_PRICE_VALIDATION = {
  message: {
    required: 'Biaya Penyimpanan harus diisi',
  }
}
export const WAREHOUSE_LOCATION_VALIDATION = {
  message: {
    required: 'Lokasi Gudang harus diisi',
  }
}
export const INSURANCE_VALIDATION = {
  message: {
    required: 'Asuransi harus diisi',
  }
}
export const ADDRESS_VALIDATION = {
  message: {
    required: 'Alamat harus diisi',
  }
}

export const PHONE_VALIDATION = {
  pattern: /^\d{10,14}$/,
  message: {
    required: 'Nomor Telepon harus diisi',
    invalid: 'Nomor tidak terdaftar',
    pattern: 'Nomor Telepon minimal 10 digit & maksimal 14 digit angka'
  }
}