import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import AuthLayout from "../../components/Layout/AuthLayout/AuthLayout";
import {
  PASSWORD_VALIDATION,
  PHONE_VALIDATION,
} from "../../constants/validationConstants";
import { useValidation } from "../../hooks/useValidation";
import { login } from "../../redux/actions/userActions";
import "./LoginScreen.css";
import { RESET_ERROR } from "../../redux/constants/userConstants";

const LoginScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Hook for validate value
  const { validateValue } = useValidation();

  const { loading, error, userInfo } = userLogin;
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    
    switch (name) {
      case "phone":
        setPhone(value.replace(/\D/g, ""));
        break;
      case "password":
        setPassword(value);
        break;

      default:
        break;
    }
  };

  // Data to pass to inputText
  const inputTexts = [
    {
      name: "phone",
      label: "Nomor Telepon",
      type: "text",
      value: phone,
      placeholder: "Masukan Nomor Telepon Anda",
      onChange: onChangeHandler,
      validation: errors && errors.phone && errors.phone.message,
      message: PHONE_VALIDATION.message,
      required: true,
      mode: "phone"
      // auth: dummyData.account.email
    },
    {
      name: "password",
      label: "Kata Sandi",
      type: "password",
      value: password,
      placeholder: "Masukkan Kata Sandi Anda",
      onChange: onChangeHandler,
      validation: errors && errors.password && errors.password.message,
      message: PASSWORD_VALIDATION.message,
      required: true,
      mode: "lock-pass",
      // auth: dummyData.account.password
    },
  ];

  const submit = (e) => {
    e.preventDefault();
    const validation = validateValue(inputTexts);
    setErrors(validation);
    if (validation) return;
    dispatch(login(phone, password));
  };

  const clearErr = () => {
    dispatch({ type: RESET_ERROR });
  };

  useEffect(() => {
    if (userInfo && userInfo.Success) {
      navigate("/");
    } else {
      clearErr()
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    const onKeypress = (e) => {
      if(e.key !== 'Enter') return
      submit(e)
    }
    window.addEventListener('keypress', onKeypress)
    return () => {
      window.removeEventListener('keypress', onKeypress)
    }
  }, [window, phone, password])
  

  
  return (
    <AuthLayout
      title={"Masuk"}
      subtitle={"Silahkan masukkan Nomor Telepon dan Password anda"}
      inputTexts={inputTexts}
      errorMessage={error}
    >
      <div className="login__forgot-password">
        {/* <Link to={'/forgot-password'} >Lupa Password</Link> */}
      </div>
      <Button
        buttonColor={"primary"}
        className={"w-full mb-5 font-normal"}
        buttonLoading={loading}
        onClick={submit}
      >
        Masuk
      </Button> 
      <div className='text-center'>
        Belum punya akun? <Link to={'/register'} className='underline link-text text-pari-blue'>Daftar Sekarang</Link>
      </div>
    </AuthLayout>
  );
};

export default LoginScreen;
