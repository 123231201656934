import React from 'react'
import Accordion from '../../Accordion/Accordion'
import { IoChevronDown } from 'react-icons/io5'
import Button from '../../Button/Button'
import Moment from 'react-moment';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';

const TableDataAccordion = ({
  data,
  smallData,
  largeData,
  className = ""
}) => {
  const numberFormatter = useNumberFormatter();

  return (
    <Accordion 
      className={`full-grid ${className}`}
      htmlTag='tbody'
    >
      <tr className='full-grid'>
        {smallData.map((sm, smIdx) =>(
          <td className={sm.className} key={`sm-${smIdx}`}>{data[sm.data]}</td>
        ))}
        <td className='table__data__icon'>
          <span><IoChevronDown/></span>
        </td>
      </tr>
      <tr className='full-grid'>
        {largeData.map((lg, lgIdx) => {
          return (
            <td className={`full-grid text-left items-center`} key={`lg-${lgIdx}`}>
              <span className='col-span-4'>{lg.title}</span>
              <span className='flex justify-center col-span-1'>:</span>
              <span className='col-span-7'>
                {lg.action ? (
                  <Button
                    className='col-span-4'
                    buttonSize={'sm'}
                    buttonColor={lg.action.color}
                    onClick={() => lg.action.onClick(data)}
                  > 
                    {lg.action.title}
                  </Button>
                ) : lg.data.includes("Date") ? (
                  data[lg.data] ? (
                    <Moment format="DD/MM/YY">{data[lg.data]}</Moment>
                  ) : (
                    <span>-</span>
                  )
                ) : lg.data === "TotalItemPrice" ? (
                  <span>Rp. {numberFormatter.price(data[lg.data])}</span>
                ) : lg.data === "ItemValue" ? (
                  <span>{numberFormatter.price(data[lg.data])} kg</span>
                ) : (
                  data[lg.data]
                )}
              </span>
              
            </td>
          )
        })}
      </tr>
    </Accordion>
  )
}

export default TableDataAccordion