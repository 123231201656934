import React from "react";
import Moment from "react-moment";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import ReceiptDetailData from "./ReceiptDetailData";
import { useWindowDimension } from "../../hooks/useWindowsDimension";
import { useFetch } from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import MessageBox from "../../components/Boxes/MessageBox/MessageBox";
import { useSelector } from "react-redux";
import MobileHeader from "../../components/Mobile/MobileHeader/MobileHeader";
import Heading from "../../components/Heading/Heading";
import { useDownload } from "../../hooks/useDownload";
import Button from "../../components/Button/Button";
import { IoChevronBack } from "react-icons/io5";

const ReceiptDetailScreen = () => {
  const { is1024, isSmall } = useWindowDimension();
  const numberFormatter = useNumberFormatter();
  const params = useParams();
  const { agreement_id } = params || {};
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  const {
    data: ReceiptData,
    loading,
    error,
  } = useFetch({
    url: `/agreement/detail/${agreement_id}`,
    defaultValue: {},
    formatter: (res) => res?.Data,
  });

  const { FullName, PhoneNumber } = ReceiptData?.User || {};

  const combineData = ReceiptDetailData?.detailData?.map((item) => {
    const { data, ...rest } = item || {};
    return {
      ...rest,
      data,
      value:
        data === "FullName"
          ? FullName
          : data === "WarehousePhoneNumber"
          ? PhoneNumber
          : ReceiptData[data],
    };
  });
  const combineCostData = ReceiptDetailData?.detailCost?.map((item) => {
    const { data, ...rest } = item || {};
    return {
      ...rest,
      data,
      value: ReceiptData[data],
    };
  });

  const { download } = useDownload({
    agreementID: agreement_id,
    url: `/agreement/detail/${agreement_id}/receipt`,
  });

  const handleDownload = () => {
    download();
  };

  const backButton = {
    className: "flex items-center gap-1 rounded-lg",
    buttonSize: "md",
    buttonColor: "transparent",
    onClick: () => navigate(-1),
  };

  return (
    <div className="md:p-5 lg:p-0">
      {!is1024 && userInfo && <MobileHeader title={"Surat Perjanjian"} />}
      {loading ? (
        <div className="min-h-screen p-5">
          <Skeleton height={20} className={"mb-5"} />
          {Array(10)
            .fill(0)
            .map((_, i) => (
              <div key={i} className={"mb-4 space-y-2"}>
                <Skeleton />
                <Skeleton />
              </div>
            ))}
        </div>
      ) : error || !ReceiptData ? (
        <div className="flex justify-center w-full p-5">
          <MessageBox>{error || "Data tidak ditemukan"}</MessageBox>
        </div>
      ) : (
        <div className="p-5">
          <div className="flex items-center justify-between pb-3">
            <Heading title={"Surat Perjanjian"} titleSize={"base"} />
          </div>
          <div className="flex flex-col gap-4 pt-4 pb-4 md:grid md:grid-cols-3 lg:grid lg:grid-cols-4">
            {combineData.map((detail, detailIdx) => {
              const { title, data } = detail || {};
              const value = renderedDataValue({
                detail,
                formatter: numberFormatter,
              });

              const isAddress = data === "Address";
              const isLocation = data === "WarehouseLocation";

              return (
                <div
                  key={`detail-${detailIdx}`}
                  className={`flex flex-col text-sm space-y-1 ${
                    (isAddress || isLocation) && "md:col-span-3 lg:col-span-2"
                  }`}
                >
                  <span className="text-sm text-slate-400">{title}</span>
                  <span className="text-base font-semibold">{value}</span>
                </div>
              );
            })}
          </div>
          <hr />
          <div className="py-4 space-y-3">
            {combineCostData.map((cost, costIdx) => {
              const { title } = cost || {};
              const value = renderedCostValue({
                cost,
                formatter: numberFormatter,
              });
              return (
                <div
                  key={`detail-${costIdx}`}
                  className={`${
                    isSmall
                      ? "flex flex-col space-y-1"
                      : "flex justify-between items-center gap-3"
                  }`}
                >
                  <span
                    className={`${
                      !is1024
                        ? "text-sm text-slate-400"
                        : "text-base font-medium"
                    } `}
                  >
                    {title}
                  </span>
                  <span className="text-base font-semibold">{value}</span>
                </div>
              );
            })}
          </div>
          <hr />
          <div
            className={`${
              isSmall ? "flex justify-center" : "flex justify-end"
            }`}
          >
            <div
              className={`${
                !userInfo && !is1024 && "w-full"
              } flex flex-col items-center gap-2 pt-4`}
            >
              {userInfo && (
                <>
                  <img src={ReceiptData?.QR} alt=".." width={100} />
                  <div className="w-32 text-center">
                    <span>Scan disini untuk melihat Perjanjian anda</span>
                  </div>
                </>
              )}
              <Button
                className={`${isSmall ? "w-full" : "w-32"}`}
                onClick={handleDownload}
              >
                Download
              </Button>
            </div>
          </div>
          <>
            {userInfo && is1024 && (
              <div className="fixed bottom-0 flex py-2">
                <Button {...backButton}>
                  <IoChevronBack />
                  <span>Ke Halaman Perjanjian</span>
                </Button>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default ReceiptDetailScreen;

const booleanDetails = (valueRaw) => {
  const value = valueRaw?.toLowerCase();
  const isValue = value.includes("value");
  const isPrice = value.includes("price");
  const isDate = value.includes("date");
  const isLocation = value.includes("location");

  return {
    isValue,
    isPrice,
    isDate,
    isLocation,
  };
};

const renderedDataValue = ({ detail, formatter }) => {
  const { value, data } = detail || {};
  const { isDate, isLocation } = booleanDetails(data);

  if (isDate && !value) return <>-</>;
  if (isDate) return <Moment format="DD/MM/YY">{value}</Moment>;
  if (isLocation) return <>{value.toUpperCase()}</>;
  return value;
};
const renderedCostValue = ({ cost, formatter }) => {
  const { value, data } = cost || {};
  const { isPrice, isValue } = booleanDetails(data);

  if (isPrice) return <>Rp. {formatter.price(value)}</>;
  if (isValue) return <>{formatter.price(value)} kg</>;
  return value;
};
