import React from "react";
import Moment from "react-moment";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import Button from "../../Button/Button";
import { IoEyeOutline } from "react-icons/io5";

const TableData = ({ data, dataIdx, largeData }) => {
  const numberFormatter = useNumberFormatter();
  return (
    <tbody className="full-grid">
      <tr className="table__row">
        <td className="flex-shrink-0 w-10">{dataIdx}</td>
        {largeData.map((lg, lgIdx) => (
          <td className={`flex-shrink-0 ${lg.className}`} key={`lg-${lgIdx}`}>
            {lg.action ? (
              <div className="flex items-center justify-center">
                <Button
                  className=""
                  buttonSize={"sm"}
                  buttonType={"border"}
                  buttonColor={"gray"}
                  onClick={() => lg.action.onClick(data)}
                >
                  <IoEyeOutline />
                </Button>
              </div>
            ) : lg.data.includes("Date") ? (
              data[lg.data] ? (
                <Moment format="DD/MM/YY">{data[lg.data]}</Moment>
              ) : (
                <span>-</span>
              )
            ) : lg.data === "TotalItemPrice" ? (
              <span>Rp. {numberFormatter.price(data[lg.data])}</span>
            ) : lg.data === "ItemValue" ? (
              <span>{numberFormatter.price(data[lg.data])} kg</span>
            ) : (
              data[lg.data]
            )}
          </td>
        ))}
      </tr>
    </tbody>
  );
};

export default TableData;
