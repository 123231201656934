const agreementPickedData = {
  smallData: [
    {
      id: "1",
      className: `col-span-4`,
      title: "Nama",
      data: "Name",
    },
    {
      id: "2",
      className: `col-span-6`,
      title: "NIK",
      data: "NIK",
    },
  ],
  largeData: [
    {
      id: "1",
      title: "Nama",
      data: "Name",
      className: "w-1/12",
    },
    {
      id: "2",
      title: "NIK",
      data: "NIK",
      className: "w-36",
    },
    {
      id: "3",
      title: "Tgl. Penerimaan",
      data: "ItemReceiptDate",
      className: "w-24",
    },
    {
      id: "4",
      title: "Tgl. Pengeluaran",
      data: "ItemReleaseDate",
      className: "w-24",
    },
    {
      id: "5",
      title: "Nama Barang",
      data: "ItemName",
      className: "w-1/12",
    },
    {
      id: "6",
      title: "Total Harga Barang",
      data: "TotalItemPrice",
      className: "w-32",
    },
    {
      id: "7",
      title: "Kuantitas",
      data: "ItemValue",
      className: "w-28",
    },
    {
      id: "8",
      title: "Aksi",
      className: "w-32  ",
      action: {
        title: "Lihat Detail",
        onClick: (data) => (window.location.href = `/agreement/${data?.ID}`),
      },
    },
  ],
};

export default agreementPickedData;
