import { useEffect, useState } from "react";

export const useWindowDimension = () => {
  const [offset, setOffset] = useState(0);

  const [dimension, setDimension] = useState({ height: 0, width: 0 });
  const { height, width } = dimension;
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    const handleResize = () =>
      setDimension({ width: window.innerWidth, height: window.innerHeight });

    const handleScroll = () => setOffset(window.pageYOffset);

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);

    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, [isFirstRender]);

  const isSmall = width < 768;
  const isMedium = width >= 992;
  const isLarge = width >= 1200;
  const isTabOrPhone = !(isMedium || isLarge);
  const isSmallLaptop = width <= 1100;
  const is1024 = width >= 1024;
  const is1366 = width >= 1366;
  const is1440 = width >= 1440;
  const is992 = width <= 992;
  return {
    offset,
    height,
    width,
    isMedium,
    isSmall,
    isLarge,
    isTabOrPhone,
    isSmallLaptop,
    is1024,
    is1440,
    is992,
    is1366
  };
};
