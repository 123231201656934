import axios from "axios";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../constants/urlConstants";

export const useFetch = ({
  url,
  formatter = (val) => val,
  defaultValue = [],
  woInit,
  defaultLoading,
  afterSuccess,
  baseUrl = API_BASE_URL,
  headers = {},
  errorHandler = () => {},
}) => {
  const [loading, setLoading] = useState(
    defaultLoading ? defaultLoading : !woInit
  );
  const [data, setData] = useState(defaultValue);
  const [error, setError] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseUrl}${url}`, { headers });
      setData((prev) => {
        return formatter(data, prev)
      })
      afterSuccess && afterSuccess(data);
    } catch (error) {
      errorHandler();
      setError(
        error.response && error.response.data.message
          ? error.response && error.response.data.message
          : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (woInit) return;
    getData();
  }, []);

  return { data, loading, refetch: getData, setData, error };
};
