import { useEffect, useRef } from "react"

const useEventListener = (
  eventType,
  callBack,
  element = window
) => {
  const callBackRef = useRef(callBack)

  useEffect(() => {
    callBackRef.current = callBack
  }, [callBack])

  useEffect(() => {
    const handler = e => callBackRef.current(e)
    element.addEventListener(eventType, handler)
  
    return () => element.removeEventListener(eventType, handler)
  }, [eventType, element])
}

export default useEventListener
