import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { ActionAlert } from "../../../components/Alert/variants/ActionAlert/ActionAlert";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import {
  ADDRESS_VALIDATION,
  ITEM_NAME_VALIDATION,
  ITEM_PRICE_VALIDATION,
  ITEM_RECEIPT_DATE_VALIDATION,
  ITEM_RELEASED_DATE_VALIDATION,
  ITEM_VALUE_VALIDATION,
  NAME_VALIDATION,
  NIK_VALIDATION,
  PHONE_VALIDATION,
  STORAGE_PRICE_VALIDATION,
  WAREHOUSE_LOCATION_VALIDATION,
} from "../../../constants/validationConstants";
import { useCreate } from "../../../hooks/useCreate";
import { useGetDate } from "../../../hooks/useGetDate";
import { useValidation } from "../../../hooks/useValidation";
import { useWindowDimension } from "../../../hooks/useWindowsDimension";
import "./AgreementForm.css";

const AgreementForm = ({
  data = {
    phoneNumber: "",
    name: "",
    NIK: "",
    itemName: "",
    itemValue: "",
    itemPrice: "",
    storagePrice: "",
    totalStoragePrice: "",
    warehouseLocation: "",
    insurance: "",
    ItemReceiptDate: new Date(),
    itemReleaseDate: new Date(),
    address: "",
  },
  submit,
}) => {
  const [name, setName] = useState(data.name);
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber);
  const [NIK, setNIK] = useState(data.NIK);
  const [itemName, setItemName] = useState(data.itemName);
  const [itemValue, setItemValue] = useState(data.itemValue);
  const [itemPrice, setItemPrice] = useState(data.itemPrice);
  const [storagePrice, setStoragePrice] = useState(data.storagePrice);
  const [warehouseLocation, setWarehouseLocation] = useState(
    data.warehouseLocation
  );
  const [insurance, setInsurance] = useState(data.insurance);
  const [ItemReceiptDate, setItemReceiptDate] = useState(data.ItemReceiptDate);
  const [itemReleaseDate, setItemReleasedDate] = useState(
    new Date(data.itemReleaseDate.setDate(data.itemReleaseDate.getDate() + 2))
  );
  const [address, setAddress] = useState(data.address);
  const [totalStoragePrice, setTotalStoragePrice] = useState(
    data.totalStoragePrice
  );
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  const [type, setType] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [agreementType, setAgreementType] = useState("");
  const navigate = useNavigate();
  const getDate = useGetDate();
  const { isSmall } = useWindowDimension();
  const { validateValue } = useValidation();
  const isLimit = agreementType === "limit";
  const isLimitless = agreementType === "limitless";

  const token = JSON.parse(Cookies.get("userInfo")) || {};
  const headers = {
    Authorization: `Bearer ${token?.Data?.Token}`,
    "Content-Type": "application/json",
  };

  const { postData, loading: submitLoading } = useCreate({
    url: "/agreement",
    headers,
    afterSuccess: () => {
      setAlert({
        variant: "success",
        title: `Berhasil`,
        subtitle: `Anda berhasil menyetujui Perjanjian`,
        actions: [
          {
            title: `Ke halaman Perjanjian`,
            onClick: () => navigate("/agreement"),
            color: "primary",
            variant: "outline",
          },
          {
            title: `OK`,
            onClick: () => window.location.reload(),
            color: "secondary",
          },
        ],
      });
    },
    errorHandler: (error) => {
      const is400 = error.includes("400");
      setAlert({
        variant: "danger",
        title: `Gagal`,
        subtitle: is400 ? "Masih Ada Perjanjian yang Aktif" : error,
        actions: [
          {
            title: "OK",
            onClick: () => setAlert(false),
            color: "orange",
          },
        ],
      });
    },
  });

  const { postData: checkPhone, loading } = useCreate({
    url: "/agreement/phonenumber/check",
    headers,
    afterSuccess: (res) => {
      const { Data } = res?.data || {};
      if (Data) {
        setName(Data?.FullName);
        setNIK(Data?.NIK);
        setAddress(Data?.Address);
      }
    },
    errorHandler: (error) => {
      let errorToPush = errors ? { ...errors } : {};
      if (error) {
        errorToPush.phoneNumber = { message: "Nomor tidak terdaftar" };
        setName("");
        setNIK("");
        setAddress("");
      }
      setErrors(errorToPush);
    },
  });

  function getNumberOfDay(first, second) {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round((second - first) / oneDay);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const removeNonNumeric = (num) => {
    return num.toString().replace(/[^0-9]/g, "");
  };

  function removeDots(value) {
    if (typeof value === "string") {
      return value.replace(/\./g, "");
    }
    return value;
  }

  const sellerDataHandler = (e) => {
    e.preventDefault();
    const payload = { phoneNumber };
    let errorToPush = {};

    inputTexts.map((input) => {
      if (input.name === "phoneNumber") {
        if (input.required && typeof input.value === "string")
          validateString(input, errorToPush);
      }
    });

    if (Object.entries(errorToPush).length !== 0) return setErrors(errorToPush);

    checkPhone(payload);
  };

  const COMMON_CLASS_NAME = "w-full lg:w-[49%] lg:pb-5";

  const buttonTypes = [
    {
      name: "Hingga Batas Waktu",
      className: `px-3 text-sm h-9 font-medium transition duration-300 ${
        isLimit
          ? "bg-pari-blue-600 text-white"
          : "bg-transparent text-pari-blue-500"
      } lg:hover:text-white lg:hover:bg-pari-blue-600 rounded-lg border border-pari-blue-500 hover:border-transparent focus:bg-pari-blue-600 focus:text-white`,
      onClick: (e) => {
        e.preventDefault();
        setShowForm(true);
        setType(false);
        setAgreementType("limit");
      },
    },
    {
      name: "Tanpa Batas Waktu",
      className: `px-3 text-sm h-9 font-medium transition duration-300 ${
        isLimitless
          ? "bg-pari-blue-600 text-white"
          : "bg-transparent text-pari-blue-500"
      } lg:hover:text-white lg:hover:bg-pari-blue-600 rounded-lg border border-pari-blue-500 hover:border-transparent  focus:bg-pari-blue-600 focus:text-white`,
      onClick: (e) => {
        e.preventDefault();
        setShowForm(true);
        setType(true);
        setAgreementType("limitless");
      },
    },
  ];

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    let transformValue = value;
    const maxLengthNIK = transformValue.length <= 16;
    if (
      name === "itemValue" ||
      name === "itemPrice" ||
      name === "storagePrice"
    ) {
      transformValue = numberWithCommas(removeNonNumeric(value));
    }
    switch (name) {
      case "name":
        setName(transformValue.replace(/[0-9]/g, ""));
        break;
      case "phoneNumber":
        setPhoneNumber(transformValue.replace(/\D/g, ""));
        break;
      case "NIK":
        maxLengthNIK && setNIK(transformValue.replace(/\D/g, ""));
        break;
      case "itemName":
        setItemName(transformValue);
        break;
      case "itemValue":
        setItemValue(transformValue);
        break;
      case "itemPrice":
        setItemPrice(transformValue);
        break;
      case "storagePrice":
        setStoragePrice(transformValue);
        break;
      case "warehouseLocation":
        setWarehouseLocation(transformValue);
        break;
      case "insurance":
        setInsurance(transformValue);
        break;
      case "address":
        setAddress(transformValue);
        break;
      default:
        break;
    }

    let errs = errors;
    delete errs[name];
  };

  const nextDay = (date, days) => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + days);
    return nextDate;
  };

  const onChangeDate = (date, selector) => {
    if (selector === "ItemReceiptDate") {
      setItemReceiptDate(date);
      const minDate = nextDay(date, 2);

      if (itemReleaseDate <= minDate) {
        setItemReleasedDate(minDate);
      }
    } else {
      setItemReleasedDate(date);
    }

    let errs = errors;
    delete errs[selector];
  };

  const inputTexts = [
    {
      id: "0",
      label: "Nomor Telepon",
      name: "phoneNumber",
      type: "number",
      value: phoneNumber,
      placeholder: "Masukkan Nomor Telepon",
      onChange: onChangeHandler,
      required: true,
      validation: errors?.phoneNumber?.message,
      message: PHONE_VALIDATION.message,
      mode: "check",
      onClick: sellerDataHandler,
      buttonLoading: loading,
    },
    {
      id: "1",
      label: "Nama Lengkap",
      name: "name",
      type: "text",
      value: name,
      onChange: onChangeHandler,
      required: true,
      validation: errors?.name?.message,
      message: NAME_VALIDATION.message,
      disabled: true,
    },
    {
      id: "2",
      label: "NIK",
      name: "NIK",
      type: "number",
      value: NIK,
      onChange: onChangeHandler,
      required: true,
      pattern: NIK_VALIDATION.pattern,
      validation: errors?.NIK?.message,
      message: NIK_VALIDATION.message,
      maxLength: 16,
      disabled: true,
    },
    {
      id: "3",
      name: "address",
      wrapperClassName: "w-full lg:pb-5",
      label: "Alamat",
      required: true,
      variant: "textarea",
      value: address,
      onChange: onChangeHandler,
      validation: errors?.address?.message,
      message: ADDRESS_VALIDATION.message,
      disabled: true,
    },
    {
      id: "4",
      name: "ItemReceiptDate",
      value: ItemReceiptDate,
      onChange: (date) => onChangeDate(date, "ItemReceiptDate"),
      label: "Tanggal Penerimaan Barang",
      dateFormat: "dd / MM / yyyy",
      variant: "datePicker",
      minDate: new Date(),
      required: true,
      validation: errors?.ItemReceiptDate?.message,
      message: ITEM_RECEIPT_DATE_VALIDATION.message,
    },
    {
      id: "5",
      name: "ItemReleaseDate",
      value: itemReleaseDate,
      onChange: (date) => onChangeDate(date, "ItemReleaseDate"),
      label: "Tanggal Pengeluaran Barang",
      dateFormat: "dd / MM / yyyy",
      variant: "datePicker",
      minDate: nextDay(ItemReceiptDate, 2),
      // required: true,
      validation: errors?.itemReleaseDate?.message,
      message: ITEM_RELEASED_DATE_VALIDATION.message,
    },
    {
      id: "6",
      label: "Nama Barang",
      name: "itemName",
      type: "text",
      value: itemName,
      placeholder: "Masukkan Nama Barang",
      onChange: onChangeHandler,
      required: true,
      validation: errors?.itemName?.message,
      message: ITEM_NAME_VALIDATION.message,
    },
    {
      id: "7",
      label: "Jumlah Kuantitas Barang",
      name: "itemValue",
      type: "text",
      value: itemValue,
      mode: "mass",
      placeholder: "Masukkan Kuantitas Barang",
      onChange: onChangeHandler,
      required: true,
      validation: errors?.itemValue?.message,
      message: ITEM_VALUE_VALIDATION.message,
    },
    {
      id: "8",
      label: "Harga Barang per kg",
      name: "itemPrice",
      type: "text",
      value: itemPrice,
      mode: "currency",
      placeholder: "Masukkan Harga Barang",
      onChange: onChangeHandler,
      required: true,
      validation: errors?.itemPrice?.message,
      message: ITEM_PRICE_VALIDATION.message,
    },
    {
      id: "8",
      label: "Total Harga Barang",
      name: "totalItemPrice",
      type: "text",
      value: numberWithCommas(
        itemPrice && itemValue
          ? +removeDots(itemPrice) * +removeDots(itemValue)
          : 0
      ),
      mode: "currency",
      disabled: true,
      // required: true,
      // validation: errors?.totalItemPrice?.message,
      // message: TOTAL_ITEM_PRICE_VALIDATION.message,
    },
    {
      id: "9",
      label: "Biaya Penyimpanan per Hari",
      name: "storagePrice",
      type: "text",
      mode: "currency",
      value: storagePrice,
      placeholder: "Masukkan Biaya Penyimpanan",
      onChange: onChangeHandler,
      required: true,
      validation: errors?.storagePrice?.message,
      message: STORAGE_PRICE_VALIDATION.message,
    },
    {
      id: "10",
      label: "Total Biaya Penyimpanan",
      name: "totalStoragePrice",
      type: "text",
      mode: "currency",
      value: numberWithCommas(
        isLimit && ItemReceiptDate && itemReleaseDate && storagePrice
          ? +removeDots(storagePrice) *
              getNumberOfDay(ItemReceiptDate, itemReleaseDate) *
              +removeDots(itemValue)
          : 0
      ),
      disabled: true,
      // required: true,
      // validation: errors?.storagePrice?.message,
      // message: STORAGE_PRICE_VALIDATION.message,
    },
    {
      id: "11",
      name: "warehouseLocation",
      wrapperClassName: "w-full lg:pb-5",
      label: "Lokasi Gudang",
      required: true,
      variant: "textarea",
      value: warehouseLocation,
      onChange: onChangeHandler,
      validation: errors?.warehouseLocation?.message,
      message: WAREHOUSE_LOCATION_VALIDATION.message,
      // disabled: true,
    },

    // {
    //   id: "8",
    //   label: "Asuransi",
    //   name: "insurance",
    //   value: insurance,
    //   placeholder: "Masukkan Asuransi",
    //   onChange: onChangeHandler,
    //   required: true,
    //   autoCompleteData: [
    //     { id: 1, value: "Asuransinya BRI" },
    //     { id: 2, value: "Allianz" },
    //     { id: 3, value: "Sinar Mas" },
    //   ],
    //   variant: "autoComplete",
    //   validation: errors?.insurance?.message,
    //   message: WAREHOUSE_LOCATION_VALIDATION.message,
    // },
  ];

  const validateString = (data, err) => {
    const stringValue = data.value.toString();
    if (stringValue === "")
      return (err[data.name] = { message: data.message.required });
    if (data.pattern && !data.pattern.test(stringValue))
      return (err[data.name] = { message: data.message.invalid });
  };

  const validateTwoDates = (firstData, secondData, err) => {
    const firstDataDate = getDate.from(firstData.value).joined;
    const secondDataDate = getDate.from(secondData.value).joined;
    if (firstDataDate > secondDataDate) {
      err[firstData.name] = { message: firstData.message.invalid };
      err[secondData.name] = { message: secondData.message.invalid };
    }
  };
  const dataToSubmit = {
    phoneNumber,
    NIK,
    ItemPrice: Number(removeDots(itemPrice) || 0),
    ItemName: itemName,
    Insurance: insurance,
    ItemReceiptDate: getDate.from(ItemReceiptDate).joinedStrip,
    Address: address,
    name,
    itemValue: Number(removeDots(itemValue) || 0),
    ...(isLimitless
      ? {}
      : { itemReleaseDate: getDate.from(itemReleaseDate).joinedStrip }),
    storagePrice: Number(removeDots(storagePrice) || 0),
    warehouseLocation: warehouseLocation,
    type: agreementType,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let errorToPush = {};

    for (let i = 0; i < inputTexts.length; i++) {
      if (inputTexts[i].required && typeof inputTexts[i].value === "string")
        validateString(inputTexts[i], errorToPush);
    }

    const receiptDate = inputTexts.find((x) => x.name === "ItemReceiptDate");
    const releasedDate = inputTexts.find((x) => x.name === "ItemReleaseDate");
    if (isLimit) {
      validateTwoDates(receiptDate, releasedDate, errorToPush);
    }

    if (Object.entries(errorToPush).length !== 0) return setErrors(errorToPush);

    postData(dataToSubmit);
  };

  const cancel = () => {
    navigate("/agreement");
  };

  useEffect(() => {
    setName("")
    setNIK("")
    setAddress("")
  }, [phoneNumber]);

  return (
    <>
      <form className="agreement-form">
        <div className="md:hidden">
          <MobileHeader title={"Tambah Perjanjian"} />
        </div>
        <div
          className="w-full overflow-y-auto md:overflow-hidden"
          // style={{ height: "calc(100vh - 168px)" }}
        >
          <div className="w-full agreement-form__inputs white-wrapper">
            <div
              className={`${
                isSmall
                  ? "flex flex-col items-center gap-2 w-full"
                  : // : "flex w-full justify-between items-center"
                    "flex flex-col items-center gap-2 w-full"
              }`}
            >
              <span>Pilih Tipe Perjanjian : </span>
              <div
                className={`${
                  isSmall ? "flex flex-col w-full space-y-2" : "space-x-2"
                }`}
              >
                {buttonTypes.map((btn, btnIdx) => (
                  <React.Fragment key={btnIdx}>
                    <button className={btn.className} onClick={btn.onClick}>
                      {btn.name}
                    </button>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          {showForm && (
            <div className="p-5 mt-2 mb-32 bg-white agreement-form__inputs md:rounded-lg lg:mb-0">
              {inputTexts.map((input, idx) => {
                if (type && input.name === "ItemReleaseDate") {
                  return null;
                }
                if (type && input.name === "totalStoragePrice") {
                  return null;
                }
                return (
                  <Input
                    id={`input-${input.id}`}
                    key={idx}
                    autoCompleteData={input.autoCompleteData}
                    dateSelected={input.selected}
                    dateFormat={input.dateFormat}
                    label={input.label}
                    name={input.name}
                    onChange={input.onChange}
                    placeholder={input.placeholder}
                    required={input.required}
                    type={input.type}
                    validation={input.validation}
                    value={input.value}
                    variant={input.variant}
                    wrapperClassName={
                      input.wrapperClassName || COMMON_CLASS_NAME
                    }
                    disabled={input.disabled}
                    mode={input.mode}
                    onClick={input.onClick}
                    maxLength={input.maxLength}
                    minDate={input.minDate}
                    buttonLoading={input.buttonLoading}
                  />
                );
              })}
            </div>
          )}
        </div>
      </form>
      <div className="fixed bottom-0 left-0 right-0 z-0 w-full bg-white border-t-[1px]">
        <div className="agreement-form__actions">
          <div className="agreement-form__buttons">
            {showForm && (
              <Button
                className="agreement-form__button md:order-2"
                onClick={submitHandler}
                buttonLoading={submitLoading}
              >
                Simpan
              </Button>
            )}

            <Button
              className="agreement-form__button md:order-1"
              buttonColor={"gray"}
              buttonType={"border"}
              onClick={cancel}
              buttonLoading={submitLoading}
            >
              Batalkan
            </Button>
          </div>
        </div>
      </div>
      {alert && <ActionAlert {...alert} />}
    </>
  );
};

export default AgreementForm;
