import React from 'react'
import './Button.css'
import { ImSpinner } from 'react-icons/im'

const Button = ({
  buttonSize, 
  buttonColor, 
  buttonType, 
  buttonLoading,
  className = '',
  boxSize,
  children, 
  onClick
}) => {
  const buttonSizes = ['sm', 'md', 'lg', 'box']
  const buttonColors = ['primary', 'secondary', 'disabled', 'gray', 'transparent','blue']
  const buttonTypes = ['normal', 'border', 'border-toggle', 'pagination', ]

  const CHECK_BUTTON_SIZE = buttonSizes.find(x => x === buttonSize) ? buttonSize : 'md'
  const CHECK_BUTTON_COLOR = buttonColors.find(x => x === buttonColor) ? buttonColor : buttonColors[0]
  const CHECK_BUTTON_TYPES = buttonTypes.find(x => x === buttonType) ? buttonType : buttonTypes[0]
  const CHECK_BOX_SIZE = CHECK_BUTTON_SIZE === 'box' ? boxSize ? `-${boxSize}` : '-6' : ''
  const CHECK_BUTTON_LOADING = buttonLoading || false

  return (
    <button 
      className={`btn btn--${CHECK_BUTTON_SIZE}${CHECK_BOX_SIZE} btn--${CHECK_BUTTON_COLOR} ${className}`} 
      aria-label={CHECK_BUTTON_TYPES} 
      onClick={onClick}
    >
      {CHECK_BUTTON_LOADING ? (
        <ImSpinner className='animate-spin'/>
      ) : (
        children
      )}      
    </button>
  )
}

export default Button