import React, { useEffect, useRef, useState } from "react";
import { IoMenu, IoNotifications, IoCloseOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FULL_LOGO_IMAGE_PATH,
  LOGO_IMAGE_PATH,
  FULL_LOGO_WHITE_PATH,
} from "../../../constants/pathConstants";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Shade from "../../Shade/Shade";
import "./GlobalLayout.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/actions/userActions";
import { routes } from "../../../utils/routes";
import Button from "../../Button/Button";
import Cookies from "js-cookie";
import { addZero } from "../../../hooks/addZero";
import { useClickOutsideComponent } from "../../../hooks/useClickOutsideComponent";
import { ActionAlert } from "../../Alert/variants/ActionAlert/ActionAlert";
import { useWindowDimension } from "../../../hooks/useWindowsDimension";

const GlobalLayout = ({
  title,
  actionButton = [],
  showSidebar = true,
  children,
}) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { is1024 } = useWindowDimension();
  const { FullName } = userInfo?.Data?.User || {};
  const { Email } = userInfo?.Data?.User || {};
  const menuRef = useRef();
  const accRef = useRef();
  const today = new Date();
  const date = today.toISOString().slice(0, 10);
  const time =
    addZero(today.getHours()) +
    ":" +
    addZero(today.getMinutes()) +
    ":" +
    addZero(today.getSeconds());
  const dateTime = date + " " + time;
  const expToken = userInfo?.Data?.ExpAt || {};
  const firstChar =
    FullName?.split(" ").map((char) => char.charAt(0).toUpperCase()) || [];
  const firstName = firstChar[0];
  const lastName = firstChar[1] || "";

  const [sidebar, setSidebar] = useState(false);
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const [shade, setShade] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [alert, setAlert] = useState(false);

  const sidebarHandler = () => {
    setSidebar(sidebar ? false : true);
    if (window.innerWidth >= 1024) return;
    setShade(shade ? false : true);
  };

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login");
  };
  const checkExpired = () => {
    if (dateTime >= expToken) {
      setAlert(true);
    }
  };

  useClickOutsideComponent(accRef, openCard, setOpenCard);

  const expPopUp = {
    size: "sm",
    className: "p-6",
    icon: (
      <img
        src={`${FULL_LOGO_IMAGE_PATH}`}
        alt={"logo"}
        width={100 * 2}
        height={28.5 * 2}
      />
    ),
    title: "Sesi Telah Berakhir",
    subtitle: "Silahkan login kembali untuk melanjutkan aktivitas Anda",
    actions: [
      {
        title: "Ke Halaman Login",
        color: "orange",
        onClick: () => logoutHandler(),
      },
    ],
  };

  useEffect(() => {
    function closeSideBar() {
      if (window.innerWidth >= 1024) return;
      setSidebar(false);
      setShade(false);
    }
    window.addEventListener("resize", closeSideBar);
    return function () {
      window.removeEventListener("resize", closeSideBar);
    };
  }, []);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    checkExpired();
  }, [dateTime, expToken, navigate]);

  useEffect(() => {
    const findSidebarMenu = () => {
      let menus = [];
      const menuToGet = ["perjanjian"];
      menuToGet.forEach((menu) => {
        const findRoute = routes.find((x) => x.name === menu);
        if (!findRoute) return;
        menus.push(findRoute);
      });
      return menus;
    };
    setSidebarMenu(findSidebarMenu());
  }, []);

  return (
    <>
      <div className="layout">
        {showSidebar && (
          <>
            <div className="sidebar__wrapper" aria-label={sidebar}>
              <div className="sidebar">
                <div className="sidebar__logo">
                  {sidebar && is1024 ? (
                    ""
                  ) : (
                    <img src={FULL_LOGO_IMAGE_PATH} alt="Logo PARI" />
                  )}
                </div>
                <div className="sidebar__menus">
                  {sidebarMenu
                    ? sidebarMenu.map((menu, i) => (
                        <Link
                          key={i}
                          to={menu.path}
                          className="sidebar__menu"
                          aria-label={
                            location.pathname.includes(menu.path)
                              ? "active"
                              : ""
                          }
                        >
                          <span>
                            <img src={menu.icon} alt={menu.icon} />
                          </span>
                          <span>{menu.name}</span>
                        </Link>
                      ))
                    : ""}
                </div>
              </div>
              <div className="sidebar__close" onClick={sidebarHandler}>
                <IoCloseOutline className="text-2xl" />
              </div>
            </div>
          </>
        )}
        <Shade shadeActive={shade} closeFunction={sidebarHandler} />

        <div
          className={`${
            showSidebar && is1024 ? "lg:ml-[13rem]" : ""
          } content__wrapper`}
        >
          <header className="header">
            {!showSidebar && is1024 ? (
              <img
                src={FULL_LOGO_WHITE_PATH}
                alt="microwarehouse-logo"
                className="h-12 px-5"
                onClick={() => navigate("/")}
              />
            ) : (
              <span
                className="header__menu header__menu--icon"
                onClick={sidebarHandler}
              >
                <IoMenu />
              </span>
            )}

            <div className="header__menus">
              <button
                ref={accRef}
                onClick={() => setOpenCard((current) => !current)}
                className="header__menu header__menu--account"
              >
                <div className="flex items-center gap-2">
                  <div className="account__image">{`${firstName}${lastName}`}</div>
                  <span>{FullName}</span>
                </div>
                {openCard && (
                  <div ref={menuRef} className="text-left dropdown__card">
                    <div className="px-4 py-3 text-sm text-gray-900">
                      <div>{FullName}</div>
                      <div className="font-medium truncate">{Email}</div>
                    </div>
                    <div className="py-2">
                      <span
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={logoutHandler}
                      >
                        Sign out
                      </span>
                    </div>
                  </div>
                )}
              </button>
              {/* <div className="header__menu header__menu--icon">
              <div className="header__notification">
                <IoNotifications />
                <div className="notification__dot"></div>
              </div>
            </div> */}
            </div>
          </header>
          <Breadcrumb />
          <div className="w-full overflow-y-auto ">
            <main
              className={`${
                is1024 && !showSidebar && "max-w-4xl mx-auto pb-20"
              } relative content`}
            >
              <div className="content__title__wrapper">
                {title ? <h1 className="content__title">{title}</h1> : ""}
                {actionButton ? (
                  <div className="content__actions">
                    {actionButton.map((button) => (
                      <div
                        key={`button-${button.id}`}
                        className={button.wrapperClassName}
                      >
                        <Button
                          className={`mr-2 ${button.className}`}
                          buttonColor={button.color}
                          buttonType={button.type}
                          buttonSize={button.size}
                          onClick={button.onClick}
                        >
                          {button.title}
                        </Button>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {children}
            </main>
          </div>
        </div>
      </div>
      {alert && <ActionAlert {...expPopUp} />}
    </>
  );
};

export default GlobalLayout;
