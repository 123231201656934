import axios from "axios";
import { useState } from "react";
import { API_BASE_URL } from "../constants/urlConstants";

export const useCreate = ({
  url,
  woInit,
  baseUrl = API_BASE_URL,
  headers = {},
  defaultValue = {},
  defaultLoading = false,
  afterSuccess,
  resultFormatter = (data) => data,
  errorHandler = () => {},
}) => {
  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState(false);
  const [body, setBody] = useState(defaultValue);

  const postData = async (value) => {
    try {
      setLoading(true);
      const response = await axios.post(`${baseUrl}${url}`, value, { headers });
      const result = resultFormatter(response);
      setBody((prev) => resultFormatter(response, prev));

      typeof afterSuccess == "function" &&
        (await afterSuccess(result, response));
    } catch (error) {
      const errorResponse =
        error.response && error.response.data.message
          ? error.response && error.response.data.message
          : error.message;
      typeof errorHandler == "function" && errorHandler(errorResponse);
      setError(errorResponse);
    } finally {
      setLoading(false);
    }
  };

  return { loading, body, setBody, postData, error };
};
