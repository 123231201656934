import React from "react";
import "./Table.css";
import TableBody from "./TableBody/TableBody";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MessageBox from "../Boxes/MessageBox/MessageBox";

const Table = ({
  loading,
  error,
  data = [],
  smallData = [],
  largeData = [],
  page,
}) => {
  return (
    <>
      {loading ? (
        <div className="w-full pt-[40%] xl:pt-[20%] relative">
          <div className="absolute inset-0">
            <Skeleton className="" height={"100%"} width={"100%"} />
          </div>
        </div>
      ) : error ? (
        <MessageBox>{JSON.stringify(error)}</MessageBox>
      ) : (
        <div className="table__wrapper ">
          <table className="table full-grid">
            <thead className="full-grid">
              <tr className="full-grid xl:hidden">
                {smallData.map((sm, smIdx) => (
                  <th className={sm.className} key={`sm-${smIdx}`}>
                    {sm.title}
                  </th>
                ))}
                <th className="col-span-2">Aksi</th>
              </tr>
              <tr className="table__head__row">
                <th className="row__number">No.</th>
                {largeData.map((lg, lgIdx) => (
                  <th
                    className={`flex-shrink-0 ${lg.className}`}
                    key={`lg-${lgIdx}`}
                  >
                    {lg.title}
                  </th>
                ))}
              </tr>
            </thead>
            {data ? (
              data.map((el, elIdx) => {
                const idx = (elIdx + 1) + ((page - 1) * 20);
                return (
                  <TableBody
                    key={elIdx}
                    data={el}
                    dataIdx={idx}
                    smallData={smallData}
                    largeData={largeData}
                  />
                );
              })
            ) : (
              <tbody />
            )}
          </table>
        </div>
      )}
    </>
  );
};

export default Table;
