import React from 'react'
import './SmallBox.css'


const SmallBox = ({title, boxColor, className, children}) => {
  const boxColors = ['blue', 'green', 'red', 'yellow']

  const CHECK_BOX_COLOR = boxColors.find(x => x === boxColor) ? boxColor : boxColors[0]
  const CHECK_CLASS_NAME = className || ''

  return (
    <div className={`small-box small-box--${CHECK_BOX_COLOR} ${CHECK_CLASS_NAME}`}>
      {title ? <div className='mb-2 text-base text-blue-400'>{title}</div> : ''}
      <div className="text-lg font-semibold">{children}</div>
    </div>
  )
}

export default SmallBox