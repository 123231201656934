// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ImageContainer = ({
  className = "",
  ratio = "1:1",
  src,
  alt,
  fit = "contain",
  position = "center",
  breakpoints,
  onClick,
  width,
  height,
  layout = "fill",
}) => {
  const [currentRatio, setCurrentRatio] = useState(ratio);
  const [paddingTop, setPaddingTop] = useState("100%");
  const [skeleton, setSkeleton] = useState(false);
  const [error, setError] = useState(false);

  const handler = {
    image: {
      onLoad: () => {
        setSkeleton(false);
      },
      onError: () => {
        setSkeleton(false);
        setError(true);
      },
    },
  };

  useEffect(() => {
    if (!breakpoints) return;
    const checkWindowWidth = () => {
      const entries = Object.entries(breakpoints);
      entries.forEach(([key, value]) => {
        if (window.innerWidth >= key) setCurrentRatio(value);
      });
    };
    checkWindowWidth();
    window.addEventListener("resize", checkWindowWidth);
    return () => {
      checkWindowWidth();
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, [breakpoints]);

  useEffect(() => {
    if (!currentRatio) return;
    const ratios = currentRatio.split(":");
    const ratioPercent = `${(Number(ratios[0]) / Number(ratios[1])) * 100}%`;
    setPaddingTop(ratioPercent);
  }, [currentRatio]);

  return (
    <div className={`flex ${className}`}>
      <div className="relative w-full" style={{ paddingTop }}>
        <div className="absolute inset-0 w-full h-full">
          {skeleton && (
            <Skeleton
              containerClassName="h-full w-full"
              height={"100%"}
              width={"100%"}
            />
          )}
          {src && (
            <img
              src={src}
              alt={error ? "./default_image.png" : alt}
              className=""
              height={height}
              width={width}
              layout={layout}
              objectFit={fit}
              onLoad={handler.image.onLoad}
              onError={handler.image.onError}
              objectPosition={position}
              onClick={onClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// export default ImageContainer;
