import AgreementScreen from "../screens/AgreementScreen/AgreementScreen";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import SuccessScreen from "../screens/SuccessScreen/SuccessScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen/ForgotPasswordScreen";
import { ICON_IMAGE_PATH} from "../constants/pathConstants";
import DashboardScreen from "../screens/DashboardScreen/DashboardScreen";
import ReceiptDetailScreen from "../screens/ReceiptDetailScreen/ReceiptDetailScreen";
import RegisterScreen from "../screens/RegisterScreen/RegisterScreen";

export const routes = [
  {
    name: 'home',
    path: '/',
    element: <HomeScreen />,
    icon: `${ICON_IMAGE_PATH}/dashboard.png`
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    element: <DashboardScreen/>,
    icon: `${ICON_IMAGE_PATH}/dashboard.png`
  },
  {
    name: 'perjanjian',
    path: '/agreement',
    element: <AgreementScreen />,
    icon: `${ICON_IMAGE_PATH}/agreement.png`
  },
  {
    name: 'tambah perjanjian',
    path: '/agreement/add',
    element: <AgreementScreen agreementType={'add'}/>,
  },
  {
    name: 'detail perjanjian',
    path: '/agreement/:id',
    element: <AgreementScreen agreementType={'detail'}/>
  },
  {
    name: 'login',
    path: '/login',
    element: <LoginScreen />
  },
  {
    name: 'lupa-password',
    path: '/forgot-password',
    element: <ForgotPasswordScreen />
  },
  {
    name: 'success',
    path: '/success',
    element: <SuccessScreen />
  },
  {
    name: 'receipt',
    path: '/receipt/:agreement_id',
    element: <ReceiptDetailScreen/>
  },
  {
    name: 'register',
    path: '/register',
    element: <RegisterScreen/>
  }
]