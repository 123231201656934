const ReceiptDetailData = {
  detailData: [
    {
      title: "Tanggal Penerimaan Barang",
      data: "ItemReceiptDate",
    },
    {
      title: "Tanggal Pengeluaran Barang",
      data: "ItemReleaseDate",
    },
    {
      title: "Nama",
      data: "Name",
    },
    {
      title: "NIK",
      data: "NIK",
    },
    {
      title: 'No. Telepon',
      data: 'PhoneNumber',
    },
    {
      title: "Nama Barang",
      data: "ItemName",
    },
    {
      title: 'Alamat Penyewa',
      data: 'Address',
    },
    {
      title: 'Nama Pemilik Gudang',
      data: 'FullName'
    },
    {
      title: 'No. Telepon Gudang',
      data: 'WarehousePhoneNumber',
    },
    {
      title: "Lokasi Gudang",
      data: "WarehouseLocation",
    },
  ],

  detailCost: [
    {
      title: "Jumlah Kuantitas Barang",
      data: "ItemValue",
    },
    {
      title: 'Harga Barang per kg',
      data: 'ItemPrice',
    },
    {
      title: "Total Harga Barang",
      data: "TotalItemPrice",
    },
    {
      title: "Biaya Penyimpanan",
      data: "StoragePrice",
    },
    {
      title: "Total Biaya Penyimpanan",
      data: "TotalRentPrice",
    }
  ],
};

export default ReceiptDetailData;