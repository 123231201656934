import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import Cookie from 'js-cookie'
import thunk from 'redux-thunk'
import { userLoginReducer, userRegisterReducer} from './reducers/userReducers';

const initialState = {
    userLogin: {
        userInfo: Cookie.get('userInfo')
        ? JSON.parse(Cookie.get('userInfo')) 
        : null
    },
};

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer
})

const composerEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer, 
    initialState, 
    composerEnhancer(applyMiddleware(thunk))
)

export default store;