const simulateDetailPickedData = [
  {
    title: "Nama Barang",
    data: "ItemName",
  },
  {
    title: "Tanggal Penerimaan Barang",
    data: "ItemReceiptDate",
  },
  {
    title: "Tanggal Pengeluaran Barang",
    data: "ItemReleaseDate",
  },
  {
    title: "Biaya Penyimpanan",
    data: "StoragePrice",
  },
  {
    title: "Jumlah Hari",
    data: "diffDay",
  },
  {
    title: "Total Biaya Penyimpanan",
    data: "TotalRentPrice",
  },

];

export default simulateDetailPickedData;
