import React from 'react'
import { forwardRef } from 'react'
import styles from './IconContainer.module.css'

export const IconContainer = forwardRef(({
  className = '',
  children,
  onClick,
  element,
  title,
}, ref) => {

  const CHECK_COMPONENT = element || 'div'

  return (
    <CHECK_COMPONENT ref={ref} className={`${styles[`icon-container`]} ${className}`} onClick={onClick} title={title}>
      {children}
    </CHECK_COMPONENT>
  )
})

IconContainer.displayName = 'Icon Container'

// export default IconContainer