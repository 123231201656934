import React from 'react'
import './MessageBox.css'

const MessageBox = ({className, children, boxColor}) => {

  const boxColors = ['red', 'yellow', 'blue']

  const CHECK_CLASS_NAME = className || ''
  const CHECK_BOX_COLOR = boxColors.find(x => x === boxColor) ? boxColor : boxColors[0]

  return (
    <div className={`message-box message-box--${CHECK_BOX_COLOR} ${CHECK_CLASS_NAME}`}>{children}</div>
  )
}

export default MessageBox