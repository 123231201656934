import React, { useEffect, useState } from 'react'
import { Link, useLocation} from 'react-router-dom'
import { routes } from '../../utils/routes'
import './Breadcrumb.css'

const Breadcrumb = () => {

  const [breadcrumb, setBreadcrumb] = useState([])

  const location = useLocation()
  
  useEffect(() => {
    const pathname =  location.pathname.split('/')
    const emptyStringIndex = pathname.indexOf('')
    pathname.splice(emptyStringIndex, 1)

    pathname.forEach((path) => {
      const findRoutes = routes.find(x => x.path.includes(path))
      if (findRoutes) {
        const indexOfPath = pathname.indexOf(path)
        if(~indexOfPath) pathname[indexOfPath] = findRoutes
      }
    })

    setBreadcrumb(pathname)
  }, [location])

  return (
    <div className='breadcrumb'>
      {breadcrumb.map((crumb, i) => (
        <span key={i}>
          {i !== 0 ? (
            <span className='mx-2'>/</span>
          ) : ''}
          {typeof crumb === 'object' ? (
            i !== breadcrumb.length - 1 
            ? <Link to={crumb.path} className={'breadcrumb__link'}>{crumb.name}</Link>
            : (crumb.name)
          ) : (crumb)}
        </span>
      ))}
    </div>
  )
}

export default Breadcrumb