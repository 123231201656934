import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GlobalLayout from '../../components/Layout/GlobalLayout/GlobalLayout'

const HomeScreen = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/agreement')
  }, [navigate])
  return (
    <GlobalLayout/>
  )
}

export default HomeScreen