import React, { useEffect, useRef, useState } from 'react'
import './InputAutoComplete.css'

const InputAutoComplete = ({
  id,
  autoCompleteData,
  className,
  name,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  value
}) => {

  const [inputValue, setInputValue] = useState(value)
  const [showOptions, setShowOptions] = useState(false)
  const [filterAutoCompleteData, setFilterAutoCompleteData] = useState(autoCompleteData || [])

  const autoCompleteInput = useRef(null)

  const CHECK_SHOW_OPTIONS = showOptions ? 'input-autocomplete__options--show' : ''

  const filterData = (data, value) => {
    let filterDataArray = []
    for (let i = 0; i < data.length; i++) {
      const lowerCaseDatavalue = data[i]?.value.toLowerCase()
      const lowerCaseValue = value.toLowerCase()
      if (lowerCaseDatavalue.includes(lowerCaseValue)) filterDataArray.push(data[i])
    }
    return filterDataArray
  }

  const checkValueToSetData = (val) => {
    setFilterAutoCompleteData(val === '' ? autoCompleteData : filterData(autoCompleteData, val))
  }

  const optionOnClickHandler = (data) => {
    setInputValue(data.value)
    onChange().setInputValue(data.value)
    onChange().onChange(data.value)
    setShowOptions(false)
  }

  const onChangeHandler = (e) => {
    const targetValue = e.target.value    
    setInputValue(targetValue)
    checkValueToSetData(targetValue)
    onChange().setInputValue(targetValue)
    onChange().onChange(targetValue)
  }

  const onFocusHandler = (e) => {
    setShowOptions(true)
    const targetValue = e.target.value    
    onFocus && onFocus(e)
    checkValueToSetData(targetValue)
    if (targetValue === '') return
    e.target.select()
  }  

  const onBlurHandler = (e) => {
    onBlur && onBlur(e)

    if(filterAutoCompleteData.length !== 0 ) return 
    setInputValue('')
    onChange().setInputValue('')
    onChange().onChange('')
  }

  useEffect(() => {
    
    if (!showOptions) return
    const windowOnClickHandler = (e) => {
      if (e.target.name !== name) return setShowOptions(false)      
    }
    window.addEventListener('click', windowOnClickHandler)
    return () => { window.removeEventListener('click', windowOnClickHandler) }

  }, [showOptions, name])

  return (
    <div className="input-autocomplete">
      <input
        ref={autoCompleteInput}
        id={id}
        name={name}
        className={className}
        value={inputValue}
        onFocus={onFocusHandler} 
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        placeholder={placeholder}
        autoComplete={'off'}
      />
      <div className={`input-autocomplete__options ${CHECK_SHOW_OPTIONS}`}>
        {filterAutoCompleteData.length > 0 ? (
          filterAutoCompleteData.map((data) => (
            <div key={data.id} className="input-autocomplete__option" onClick={() => optionOnClickHandler(data)}>{data.value}</div>
          ))
        ) : <div className="input-autocomplete__option" onClick={() => setShowOptions(false)}>Data tidak ditemukan</div>}
      </div>
    </div>
  )
}

export default InputAutoComplete