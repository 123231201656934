import React, { useEffect, useState } from "react";
import SmallBox from "../../../components/Boxes/SmallBox/SmallBox";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Select from "../../../components/Select/Select";
import Table from "../../../components/Table/Table";
import PaginationNav from "../../../components/Pagination/Pagination";
import { IoAdd, IoOptions } from "react-icons/io5";
import agreementPickedData from "./agreementPickedData";
import "./AgreementList.css";
import { useFetch } from "../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { useDebounce } from "../../../hooks/useDebounce";
import { useNavigate, useSearchParams } from "react-router-dom";

const AgreementList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const [search, setSearch] = useState("");
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  // const agreements = useGetData('GET', null, `/agreement?page=1&limit=20&order=ID DESC`)
  const searchDebounce = useDebounce(search, 500);
  const searchValue = !!search
    ? `&name=${search}&agreement_id=${search}&nik=${search}`
    : "";
  const {
    data: agreements,
    loading,
    error,
    refetch,
  } = useFetch({
    url: `/agreement?page=${page}&limit=20&order=ID DESC${searchValue}`,
    headers: {
      Authorization: `Bearer ${userInfo?.Data?.Token}`,
      "Content-Type": "application/json",
    },
  });

  const { Records } = agreements?.Data || {};
  const arrayData = Records || [];
  const pagination = agreements?.Data; // pagination info

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setSearchParams({ page: 1 });
    refetch();
  }, [searchDebounce]);

  return (
    <div className="space-y-4">
      <div>
        <SmallBox boxColor={"blue"} title={"Jumlah Perjanjian"}>
          <span className="text-4xl text-blue-500">
            {agreements?.Data?.TotalRecord}
          </span>
        </SmallBox>
      </div>
      <section className="space-y-5 white-wrapper">
        <div className="agreement-list__filter">
          <div className="xl:w-2/5">
            <h4 className="font-medium">Pencarian</h4>
            <Input
              wrapperClassName={"xl:w-full xl:order-2"}
              placeholder={"Cari Nama..."}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              mode={"search"}
            />
          </div>
          <Button
            buttonColor={"secondary"}
            onClick={() => navigate("/agreement/add")}
          >
            <IoAdd />
            <span>Tambah Perjanjian</span>
          </Button>
          {/* <div className="agreement-list__selects">
            <div className="w-5/12">
              <h4 className="font-medium">Komoditas</h4>
              <Select
                id={"comodity"}
                wrapperClassName={`flex-shrink-0`}
                data={[{ id: 0, value: "--Komoditas--" }]}
              />
            </div>
            <div className="w-3/12">
              <h4 className="font-medium">Status</h4>
              <Select
                id={"status"}
                wrapperClassName={`flex-shrink-0`}
                data={[{ id: 0, value: "--Status--" }]}
              />
            </div>
            <Button
              className="w-3/12"
              buttonColor={"primary"}
              buttonType={"border"}
            >
              <span>Terapkan Filter</span>
            </Button>
          </div> */}
          {/* <div className="agreement-list__mobile-filter">
            <Button buttonColor={"primary"} buttonType={"border"}>
              <span>
                <IoOptions />
              </span>
              <span>Filter</span>
            </Button>
          </div> */}
        </div>
        <Table
          data={arrayData}
          smallData={agreementPickedData.smallData}
          largeData={agreementPickedData.largeData}
          loading={loading}
          error={error}
          page={page}
        />
        <PaginationNav
          data={pagination}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          page={page}
        />
      </section>
    </div>
  );
};

export default AgreementList;
