import React from 'react'
import { forwardRef } from 'react'
import './InputButton.css'

const InputButton = forwardRef(({
  value,
  active = false,
  onClick,
  validation
}, ref) => {

  const CHECK_ACTIVE = active ? 'input-btn--active' : ''   
  const CHECK_VALIDATION = validation ? 'input__field--error' : ''
  
  return (
    <input 
      ref={ref}
      className={`input-btn ${CHECK_VALIDATION} ${CHECK_ACTIVE}`}
      value={value} 
      type={'button'}
      onClick={onClick}
    />
  )
})

export default InputButton