import React from "react";
import { Container } from "../../../Container/Container";
import { IoCheckmark, IoClose, IoTrashOutline } from "react-icons/io5";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import Button from "../../../Button/Button";
import { AlertBody } from "../../components/AlertBody/AlertBody";
import Alert from "../../Alert";

export const ActionAlert = ({
  constainerClassName = "",
  className = "",
  closeOutside = false,
  closeFunction,
  size = "lg",
  icon,
  title,
  subtitle,
  actions = [],
  variant,
}) => {
  const variants = [
    {
      variant: "success",
      icon: <IoCheckmark />,
      title: "Berhasil",
      className: "bg-green-600",
    },
    {
      variant: "caution",
      icon: (
        <BsFillExclamationTriangleFill className="text-yellow-400 text-7xl" />
      ),
      title: "Peringatan",
    },
    {
      variant: "danger",
      icon: <IoClose />,
      title: "Gagal",
      className: "bg-pari-red",
    },
    {
      variant: "delete",
      icon: <IoTrashOutline />,
      title: "Gagal",
      className: "bg-pari-red",
    },
  ];

  const CHECK_VARIANT =
    variants.find((x) => x.variant === variant) || variants[0];

  return (
    <Alert
      size={size}
      className={ constainerClassName }
      closeOutside={closeOutside}
      closeFunction={closeFunction}
    >
      <AlertBody
        className={`flex flex-col items-center text-center space-y-5 ${className}`}
      >
        {icon ? (
          icon
        ) : (
          <Container.Icon
            className={`rounded-full text-3xl h-16 w-16 text-white ${CHECK_VARIANT.className}`}
          >
            {CHECK_VARIANT.icon}
          </Container.Icon>
        )}
        <div className="flex flex-col space-y-1">
          <div className="text-2xl font-bold">
            {title || CHECK_VARIANT.title}
          </div>
          {subtitle && <div className="">{subtitle}</div>}
        </div>
        <div
          className={`flex items-center w-full font-bold ${
            actions.length < 3 ? "space-x-3" : "flex-col space-y-2"
          }`}
        >
          {actions.map((act, actIdx) => (
            <Button
              key={actIdx}
              className="w-full"
              buttonColor={act.color}
              onClick={act.onClick}
            >
              {act.title}
            </Button>
          ))}
        </div>
      </AlertBody>
    </Alert>
  );
};

// export default ActionAlert
