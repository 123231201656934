import React, { useCallback, useEffect, useState } from 'react'

import useEventListener from '../../../hooks/useEventListener'
import TableDataAccordion from '../TableDataAccordion/TableDataAccordion'
import TableData from '../TableData/TableData'

const TableBody = ({data, dataIdx , smallData , largeData }) => {
  
  const [bodyElement, setBodyElement] = useState(null)

  const bodySetter = useCallback(() => {   
    const elementProps = {
      data: data,
      dataIdx: dataIdx,
      smallData: smallData,
      largeData: largeData,
    }        
    const CHECK_WINDOW_WIDTH = window.innerWidth >= 1280 ? <TableData {...elementProps}/> : <TableDataAccordion {...elementProps}/>     
    setBodyElement(CHECK_WINDOW_WIDTH)
  }, [data, dataIdx, smallData, largeData,])

  useEffect(() => {
    bodySetter()
  }, [bodySetter])

  useEventListener('resize', () => bodySetter())
  
  return bodyElement
}

export default TableBody