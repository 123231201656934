export const IMAGE_PATH = '../assets/image'

// LOGO
export const LOGO_IMAGE_PATH = `${IMAGE_PATH}/logo/micro-logo.png`
export const FULL_LOGO_IMAGE_PATH = `${IMAGE_PATH}/logo/PARI-Warehouse.png`
export const FULL_LOGO_WHITE_PATH = `${IMAGE_PATH}/logo/PARI-Warehouse-Putih.png`

// SCREEN

export const SHAPES_IMAGE_PATH = `${IMAGE_PATH}/shapes`
export const WAREHOUSE_IMAGE_PATH = `${IMAGE_PATH}/Gudang&Truck.png`

// ICON
export const ICON_IMAGE_PATH = `${IMAGE_PATH}/icon`