import React from 'react'

export const AlertBody = ({
  className = '',
  children
}) => {
  return (
    <div className={`p-5 z-50 ${className}`}>
      {children}
    </div>
  )
}

// export default AlertBody