import Axios from "axios";
import Cookie from "js-cookie";
import {
  API_BASE_URL,
  CONTENT_TYPE_JSON,
  LOGIN_PATH,
  REGISTER_PATH,
} from "../../constants/urlConstants";
import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_LOGOUT,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_SUCCESS,
} from "../constants/userConstants";

export const login = (phoneNumber, Password) => async (dispatch) => {
  const loginConfig = {
    url: LOGIN_PATH,
    method: "POST",
    baseURL: API_BASE_URL,
    headers: CONTENT_TYPE_JSON,
    data: { phoneNumber, Password },
  };
  dispatch({ type: USER_LOGIN_REQUEST, payload: { phoneNumber, Password } });
  try {
    const { data } = await Axios(loginConfig);
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    Cookie.set("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.Message
          ? error.response.data.Message
          : error.message,
        
    });
  }
};

export const register = (dataList) => async (dispatch) => {
  // const { email, password } = dataList
  const registerConfig = {
    url: REGISTER_PATH,
    method: "POST",
    baseURL: API_BASE_URL,
    headers: CONTENT_TYPE_JSON,
    data: dataList,
  };
  dispatch({ type: USER_REGISTER_REQUEST, payload: { dataList } });
  try {
    const { data } = await Axios(registerConfig);
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    // dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.Message
          ? error.response && error.response.data.Message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  Cookie.remove("userInfo");
  dispatch({ type: USER_LOGOUT });
};
