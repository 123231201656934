import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import MessageBox from "../../../components/Boxes/MessageBox/MessageBox";
import Skeleton from "react-loading-skeleton";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import Moment from "react-moment";
import agreementDetailPickedData from "./agreementDetailPickedData";
import Heading from "../../../components/Heading/Heading";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import { useFetch } from "../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { useWindowDimension } from "../../../hooks/useWindowsDimension";
import Button from "../../../components/Button/Button";
import Alert from "../../../components/Alert/Alert";
import Typography from "../../../components/Typography/Typography";
import simulateDetailPickedData from "./simulateDetailPickedData";
import { useCreate } from "../../../hooks/useCreate";
import { ActionAlert } from "../../../components/Alert/variants/ActionAlert/ActionAlert";

const AgreementDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params || {};
  const numberFormatter = useNumberFormatter();
  const { userInfo } = useSelector((state) => state.userLogin);
  const token = userInfo;
  const { is1024 } = useWindowDimension();

  const [isLimitless, setIsLimitless] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState(null);
  
  const {
    data: detailData,
    refetch,
    loading,
    error,
  } = useFetch({
    url: `/agreement/${id}`,
    headers: {
      Authorization: `Bearer ${token?.Data?.Token}`,
      "Content-Type": "application/json",
    },
    formatter: (res) => res?.Data,
    woInit: true,
  });

  const {
    data: dataSimulate,
    loading: loadingSimulate,
    error: errorSimulate,
    refetch: refetchSimulate,
  } = useFetch({
    url: `/agreement/${id}/simulate/rent`,
    woInit: true,
    headers: {
      Authorization: `Bearer ${token?.Data?.Token}`,
      "Content-Type": "application/json",
    },
    formatter: (res) => res?.Data,
    woInit: true,
  });

  const { postData, loading: endRentLoading } = useCreate({
    url: `/agreement/${id}/end/rent`,
    headers: {
      Authorization: `Bearer ${token?.Data?.Token}`,
      "Content-Type": "application/json",
    },
    afterSuccess: () => {
      setAlert({
        variant: "success",
        title: `Berhasil`,
        subtitle: `Anda berhasil mengakhiri sewa`,
        actions: [
          {
            title: `OK`,
            onClick: () => {
              window.location.reload();
              refetch();
            },
            color: "orange",
          },
        ],
      });
    },
    errorHandler: (error) => {
      setAlert({
        variant: "danger",
        title: `Gagal`,
        subtitle: error,
        actions: [
          {
            title: "OK",
            onClick: () => setAlert(false),
            color: "orange",
          },
        ],
      });
    },
  });
  
  const { AgreementID, ItemReceiptDate } = detailData || {};
  
  function getNumberOfDay(first, second) {
    const oneDay = 24 * 60 * 60 * 1000;
    let diffDay = Math.round((second - first) / oneDay);
    return diffDay;
  }

  const isSameDate = (agreementDate) => {
    const currentDate = new Date();
    const agreementDateObj = new Date(agreementDate);

    const isSameYear =
      currentDate.getFullYear() === agreementDateObj.getFullYear();
    const isSameMonth = currentDate.getMonth() === agreementDateObj.getMonth();
    const isSameDay = currentDate.getDate() === agreementDateObj.getDate();

    return isSameYear && isSameMonth && isSameDay;
  };
  // const { loading, error, dataList } = agreements;
  // const data = detailData && detailData?.Data?.Records
  // const detail = data ? data.find(x => x.NIK === params.id) : null

  const simulateButton = [
    {
      title: "Batal",
      buttonType: "border",
      buttonColor: "transparent",
      onClick: () => setShowModal(false),
    },
    {
      title: "Akhiri",
      buttonType: "",
      buttonColor: "",
      onClick: () => postData(),
      buttonLoading: endRentLoading,
    },
  ];

  const combineData = agreementDetailPickedData?.map((item) => {
    const { data, ...rest } = item || {};
    return {
      ...rest,
      data,
      value: detailData[data],
    };
  });

  const combineSimulateData = simulateDetailPickedData?.map((item) => {
    const { data, ...rest } = item || {};
    return {
      ...rest,
      data,
      value:
        data === "diffDay"
          ? getNumberOfDay(
              new Date(dataSimulate?.ItemReceiptDate),
              new Date(dataSimulate?.ItemReleaseDate)
            )
          : dataSimulate[data],
    };
  });

  const showAgreementReceipt = () => {
    navigate(`/receipt/${AgreementID}`);
  };

  const backButtonHandler = () => {
    navigate(-1);
  };
  const backButton = {
    className: "flex items-center gap-1 rounded-lg",
    buttonSize: "md",
    buttonColor: "transparent",
    onClick: backButtonHandler,
  };

  useEffect(() => {
    if (detailData.Type === "limitless") {
      setIsLimitless(true);
      refetchSimulate();
    }
  }, [detailData]);

  useEffect(() => {
    if (isLimitless) {
      refetchSimulate();
    } else {
      refetch();
    }
  }, [isLimitless]);

  useEffect(() => {
    if (!id) return navigate("/agreement");
  }, [id]);

  return (
    <>
      <div className="justify-between md:flex md:flex-col ">
        <section
          className={`${
            !is1024 && "fixed"
          } inset-0 flex flex-col bg-white divide-y-4 md:p-5 lg:white-wrapper`}
        >
          <div className="flex flex-col flex-1 w-full overflow-y-auto">
            {!is1024 && <MobileHeader title={"Detail Perjanjian"} />}
            {loading ? (
              <div className="p-5">
                <Skeleton height={20} className={"mb-5"} />
                {Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <div key={i} className={"mb-4 space-y-2"}>
                      <Skeleton />
                      <Skeleton />
                    </div>
                  ))}
              </div>
            ) : error || !detailData ? (
              <div className="flex justify-center w-full p-5">
                <MessageBox>{error || "Data tidak ditemukan"}</MessageBox>
              </div>
            ) : (
              <div className="flex flex-col px-5 space-y-4 overflow-y-auto lg:overflow-hidden">
                <div className="flex items-center justify-between py-3">
                  <Heading title={"Data Perjanjian"} titleSize={"base"} />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridColumnGap: "16px",
                    gridTemplateColumns: "repeat (2, 1fr)",
                    gridRowGap: "20px",
                  }}
                >
                  {combineData.map((detail, detailIdx) => {
                    const { title } = detail || {};
                    const isAddress = title === "Alamat Penyewa";
                    const isLocation = title === "Lokasi Gudang";

                    const value = renderedValue({
                      detail,
                      formatter: numberFormatter,
                    });

                    const GridColumn = () => {
                      if (!is1024) return "1 / span 4";
                      if (isLocation) return "1 / span 2";
                      if (isAddress) return "3 / span 2";
                      return "auto auto";
                    };

                    return (
                      <div
                        key={`detail-${detailIdx}`}
                        className={`space-y-1`}
                        style={{
                          gridColumn: GridColumn(),
                        }}
                      >
                        <h6 className="text-sm text-slate-400">{title}</h6>
                        <h6 className="text-base font-semibold">{value}</h6>
                      </div>
                    );
                  })}
                </div>
                <div className="w-full h-1 bg-slate-100"></div>
                <div className="flex items-center justify-between pb-4 md:pb-0">
                  <Heading title={"Surat Perjanjian"} titleSize={"base"} />
                  <Button
                    buttonSize={"md"}
                    buttonColor={"transparent"}
                    onClick={showAgreementReceipt}
                  >
                    Lihat Surat Perjanjian
                  </Button>
                </div>
                {!is1024 && isLimitless && !isSameDate(ItemReceiptDate) && (
                  <div className="w-full pb-4">
                    <Button
                      className="w-full"
                      onClick={() => setShowModal(true)}
                    >
                      Akhiri Waktu Sewa
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
        {is1024
          ? showModal && (
              <Alert size={"2xl"}>
                <Alert.Body>
                  <Typography component={"div"} variant={"h5"} weight={"bold"}>
                    {"Simulasi Akhiri Sewa"}
                  </Typography>
                  <div className="grid grid-cols-2 gap-5 py-4">
                    {combineSimulateData.map((detail, detailIdx) => {
                      const { title } = detail || {};

                      const value = renderedValue({
                        detail,
                        formatter: numberFormatter,
                      });

                      return (
                        <div
                          key={`detail-${detailIdx}`}
                          className={`space-y-1`}
                          style={{
                            gridColumn: "auto auto",
                          }}
                        >
                          <h6 className="text-sm text-slate-400">{title}</h6>
                          <h6 className="text-base font-semibold">{value}</h6>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-end gap-2">
                    {simulateButton.map((btn, btnIdx) => (
                      <React.Fragment key={btnIdx}>
                        <Button
                          onClick={btn.onClick}
                          buttonType={btn.buttonType}
                          buttonColor={btn.buttonColor}
                          buttonLoading={btn.buttonLoading}
                        >
                          {btn.title}
                        </Button>
                      </React.Fragment>
                    ))}
                  </div>
                </Alert.Body>
              </Alert>
            )
          : showModal && (
              <div className="fixed inset-0 z-50 flex flex-col overflow-y-auto bg-white md:white-wrapper">
                <MobileHeader
                  title={"Simulasi Akhiri Sewa"}
                  onClick={() => setShowModal(false)}
                />
                {loadingSimulate ? (
                  <div className="p-5">
                    <Skeleton height={20} className={"mb-5"} />
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <div key={i} className={"mb-4 space-y-2"}>
                          <Skeleton />
                          <Skeleton />
                        </div>
                      ))}
                  </div>
                ) : errorSimulate || !dataSimulate ? (
                  <div className="flex justify-center w-full p-5">
                    <MessageBox>{error || "Data tidak ditemukan"}</MessageBox>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col px-5 py-5 space-y-4">
                      <div
                        style={{
                          display: "grid",
                          gridColumnGap: "16px",
                          gridTemplateColumns: "repeat (2, 1fr)",
                          gridRowGap: "20px",
                        }}
                      >
                        {combineSimulateData.map((detail, detailIdx) => {
                          const { title } = detail || {};

                          const value = renderedValue({
                            detail,
                            formatter: numberFormatter,
                          });

                          return (
                            <div
                              key={`detail-${detailIdx}`}
                              className={`space-y-1`}
                              style={{
                                gridColumn: "1 / span 4",
                              }}
                            >
                              <h6 className="text-sm text-slate-400">
                                {title}
                              </h6>
                              <h6 className="text-base font-semibold">
                                {value}
                              </h6>
                            </div>
                          );
                        })}
                      </div>
                      <div className="w-full ">
                        <Button
                          className={"w-full"}
                          onClick={simulateButton[1].onClick}
                          buttonType={simulateButton[1].buttonType}
                          buttonLoading={simulateButton[1].buttonLoading}
                        >
                          {simulateButton[1].title}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
        {alert && <ActionAlert {...alert} />}
      </div>
      {is1024 && (
        <div className="fixed bottom-0 left-0 right-0 w-full bg-white border-t-[1px]">
          <div className="flex justify-between max-w-4xl px-5 mx-auto py-2.5">
            <Button {...backButton}>
              <IoChevronBack />
              <span>Ke Halaman Perjanjian</span>
            </Button>
            {isLimitless && !isSameDate(ItemReceiptDate) && (
              <Button onClick={() => setShowModal(true)}>
                Akhiri Waktu Sewa
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AgreementDetail;

const booleanDetails = (valueRaw) => {
  const value = valueRaw?.toLowerCase();
  const isValue = value.includes("value");
  const isPrice = value.includes("price");
  const isDate = value.includes("date");
  const isDiffDay = value.includes("day");

  return {
    isValue,
    isPrice,
    isDate,
    isDiffDay,
  };
};

const renderedValue = ({ detail, formatter }) => {
  const { value, data } = detail || {};
  const { isPrice, isValue, isDate, isDiffDay } = booleanDetails(data);

  if (isPrice) return <>Rp. {formatter.price(value)}</>;
  if (isValue) return <>{formatter.price(value)} kg</>;
  if (isDate && value) return <Moment format="DD/MM/YY">{value}</Moment>;
  if (isDate && !value) return <>-</>;
  if (isDiffDay) return <>{value} hari</>;
  return value;
};
