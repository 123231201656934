import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const DashboardScreen = () => {
  const navigate = useNavigate()
  
  useEffect(() => {
    navigate('/agreement')
  }, [navigate])
  

  return (
    <>
      Dashboard
    </>
  )
}

export default DashboardScreen