import React from 'react'
import { IoChevronBack, IoClose } from 'react-icons/io5'
import { FONT_SIZES } from '../../constants/sizesConstants'
import './Heading.css'

const Heading = ({
  title,
  titleSize,
  titleHTMLTag = 'div',
  titleClassName = '',
  subtitle,
  subtitleSize,
  subtitleHTMLTag = 'div',
  subtitleClassName = '',
}) => {
  const TitleCustomTag = titleHTMLTag
  const SubtitleCustomTag = subtitleHTMLTag

  const CHECK_TITLE_SIZE = FONT_SIZES.find(x => x === titleSize) ? titleSize : 'base'
  const CHECK_SUBTITLE_SIZE = FONT_SIZES.find(x => x === subtitleSize) ? subtitleSize : 'base'

  return (
    <>
      <TitleCustomTag className={`font-bold text-${CHECK_TITLE_SIZE} ${titleClassName}`}>
        {title}
      </TitleCustomTag>
      <SubtitleCustomTag className={`font-bold text-${CHECK_SUBTITLE_SIZE} ${subtitleClassName}`}>
        {subtitle}
      </SubtitleCustomTag>
    </>
  )
}

export default Heading