import axios from "axios";
import { useState } from "react";
import { saveAs } from "file-saver";
import { API_BASE_URL } from "../constants/urlConstants";

export const useDownload = ({
  url,
  agreementID = '',
  woInit,
  defaultLoading,
  baseUrl = API_BASE_URL,
  errorHandler = () => {},
}) => {
  const [loading, setLoading] = useState(
    defaultLoading ? defaultLoading : !woInit
  );
  const [error, setError] = useState(false);

  const printLetter = async () => {
    const { data } = await getTicketsPdf();
    const blob = new Blob([data], { type: "application/pdf" });
    saveAs(blob, `agreement-letter-${agreementID}.pdf`);
  };

  const getTicketsPdf = async () => {
    return axios.get(`${baseUrl}${url}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "arraybuffer",
    });
  };

  return { loading, download: printLetter, error };
};
