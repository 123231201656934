import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useStyle } from '../../hooks/useStyle'
import styles from './Typography.module.css'

const Typography = ({
  className = '',
  variant,
  component,
  weight,
  lineHeight,
  children,
  loading
}) => {  

  const components = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span' ]
  const weights = ['thin', 'light', 'extralight', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']
  const lineHeights = ["none", "tight", "snug", "normal", "relaxed", "loose",]
  
  const checkTypographyComponent = (defaultComponent) => components.find(x => x ===  component) ? component : defaultComponent
  const checkTypographyWeight = (defaultWeight) => weights.find(x => x ===  weight) ? weight : defaultWeight

  const widthFromHeight = (height, multiplier, unit) => {
    return { height: `${height}${unit}`, width: `${height * multiplier}${unit}`}
  }

  const skeletonHeightWidthConfig = (height) => {
    return widthFromHeight(height, 6, 'rem')
  }

  const variants = [
    {
      variant: 'h1',
      component: checkTypographyComponent('h1'),
      weight: checkTypographyWeight('bold'),
      skeleton: skeletonHeightWidthConfig(4.5)
    }, 
    {
      variant: 'h2',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('semibold'),
      skeleton: skeletonHeightWidthConfig(3)
    }, 
    {
      variant: 'h3',
      component: checkTypographyComponent('h3'),
      weight: checkTypographyWeight('medium'),
      skeleton: skeletonHeightWidthConfig(2.25)
    }, 
    {
      variant: 'h4',
      component: checkTypographyComponent('h4'),
      weight: checkTypographyWeight('normal'),
      skeleton: skeletonHeightWidthConfig(1.875)
    }, 
    {
      variant: 'h5',
      component: checkTypographyComponent('h5'),
      weight: checkTypographyWeight('normal'),
      skeleton: skeletonHeightWidthConfig(1.25)
    }, 
    {
      variant: 'h6',
      component: checkTypographyComponent('h6'),
      weight: checkTypographyWeight('normal'),
      skeleton: skeletonHeightWidthConfig(1.125)
    }, 
    {
      variant: 'subtitle',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('normal'),
      skeleton: skeletonHeightWidthConfig(1)
    }, 
    {
      variant: 'subtitle1',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('medium'),
      skeleton: skeletonHeightWidthConfig(1)
    }, 
    {
      variant: 'subtitle2',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('medium'),
      skeleton: skeletonHeightWidthConfig(0.875)
    }, 
    {
      variant: 'subtitle3',
      component: checkTypographyComponent('h2'),
      weight: checkTypographyWeight('medium'),
      skeleton: skeletonHeightWidthConfig(0.75)
    }, 
    {
      variant: 'body1',
      component: checkTypographyComponent('p'),
      weight: checkTypographyWeight('normal'),
      skeleton: skeletonHeightWidthConfig(1)
    }, 
    {
      variant: 'body2',
      component: checkTypographyComponent('p'),
      weight: checkTypographyWeight('normal'),
      skeleton: skeletonHeightWidthConfig(0.875)
    }, 
  ]
  
  const CHECK_TYPOGRAPHY_VARIANT = variants.find(x => x.variant === variant) || variants.find(x => x.variant === 'body1')
  const CHECK_TYPOGRAPHY_LINEHEIGHT = lineHeights.find(x => x === lineHeight) ? lineHeight : 'normal'

  const Component = CHECK_TYPOGRAPHY_VARIANT.component

  const classNames = [
    `tpg`,
    `tpg--${CHECK_TYPOGRAPHY_VARIANT.variant}`,
    `tpg--${CHECK_TYPOGRAPHY_VARIANT.weight}`,
    `tpg--line-height-${CHECK_TYPOGRAPHY_LINEHEIGHT}`,
  ]

  const styled = useStyle(styles, classNames)

  return (
    loading ? (
      <Skeleton width={CHECK_TYPOGRAPHY_VARIANT.skeleton.width} height={CHECK_TYPOGRAPHY_VARIANT.skeleton.height}/>
    ) : (
      <Component className={`${styled} ${className}`}>
        {children}
      </Component>
    )
  )
}

export default Typography