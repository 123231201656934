export const useValidation = () => {

  // _datas must be an array that contains objects
  
  // [
  //  {
  //   name: string,
  //   pattern: regex,
  //   message: { name: string, message: string }
  //  },
  // ]

  const validateValue = (_data) => {
    let messages = {}
    _data.forEach((data) => {
      if (!data.required && data.name !== "email") return
      const messagesObject = Object.entries(data.message)
      const checkMessages = (_key) => {
        const findMessages = messagesObject.find(([key]) => key === _key) || {}
        return { type: findMessages[0], message: findMessages[1]}
      }
      const stringValue = data.value.toString()
      if (stringValue === "" && data.name !== "email") return messages[data.name] = checkMessages('required')
      if (data.pattern && !data.pattern.test(stringValue) && stringValue) return messages[data.name] = checkMessages('pattern')
      if (data.auth && stringValue !== data.auth) return messages[data.name] = checkMessages('invalid')
    })
    if(Object.entries(messages).length === 0) messages = null
    return messages
  }
  return { validateValue }
}