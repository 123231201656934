import React, { useEffect, useState } from 'react'
import './Shade.css'

const Shade = ({shadeActive, closeFunction}) => {
  const [render, setRender] = useState(false)
  const [active, setActive] = useState(false)

  const activate = (bool = true) => {
    if (typeof bool !== 'boolean') return

    let timeouts= { render: 0, active: 10}
    if (bool === false) {
      timeouts.render = 300
      timeouts.active = 0
    }

    const setFunctions = [
      { setter: setRender, timeout: timeouts.render },
      { setter: setActive, timeout: timeouts.active },
    ]
    
    setFunctions.forEach(func => {
      setTimeout(() => {
        func.setter(bool)
      }, func.timeout)  
    })
  }

  useEffect(() => {
    if(shadeActive) return activate(true)
    activate(false)
  }, [shadeActive])

  return (
    render ? (
      <div className="shade" aria-label={active} onClick={closeFunction}></div>
    ) : ''
  )
}

export default Shade