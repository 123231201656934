import React from "react";
import { IoClose } from "react-icons/io5";
import { ActionAlert } from "./variants/ActionAlert/ActionAlert.js";
import { Toaster } from "./variants/ToasterAlert/ToasterAlert.js";
import styles from "./Alert.module.css";
import { AlertBody } from "./components/AlertBody/AlertBody.js";
import { AlertHeader } from "./components/AlertHeader/AlertHeader.js";

const Alert = ({
  className = "",
  closeOutside = false,
  closeFunction,
  size,
  children,
}) => {
  const alertSizes = [
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl",
    "6xl",
    "7xl",
    "full",
  ];

  const CHECK_ALERT_SIZE = alertSizes.find((x) => x === size)
    ? size
    : alertSizes[0];

  const closeHandler = (e) => {
    closeFunction && closeFunction(e);
  };

  return (
    <div className={styles["alert__container"]}>
      <div
        className="fixed inset-0 z-50 bg-black opacity-50"
        onClick={(e) => (closeOutside ? closeHandler(e) : "")}
      ></div>
      <div
        className={`${styles.alert} ${
          styles[`alert--${CHECK_ALERT_SIZE}`]
        } ${className}`}
      >
        {closeFunction && (
          <button className={styles.alert__close} onClick={closeHandler}>
            <IoClose />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};
export default Alert;
// Alert.Action = ActionAlert;

// Alert.Header = AlertHeader;
Alert.Body = AlertBody;

// Alert.Toaster = Toaster;
