import React, { useState, useEffect, useRef } from 'react'
import './Accordion.css'

const Accordion = ({
  children,
  className = '',
  onClick = () => '',
  htmlTag,
}) =>  {  

  const [expand, setExpand] = useState(false)

  const accordion = useRef(null)
  const CustomTag = htmlTag || 'div'

  const expandHandler = () => {
    onClick()
    setExpand(expand ? false : true)   
  }


  useEffect(() => {
    const currentAcc = accordion.current
    if (!currentAcc) return
    const panel = currentAcc.children[1]
    if (!panel) return
    if (expand) return panel.style.maxHeight = panel.scrollHeight + 'px'
    if (!expand) return panel.style.maxHeight = ''
  }, [expand])


  return (
    <CustomTag ref={accordion} className={`accordion ${className}`} onClick={expandHandler} aria-label={expand}>
      {children}
    </CustomTag>
  )
}

export default Accordion

