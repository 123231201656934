import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import AuthLayout from "../../components/Layout/AuthLayout/AuthLayout";
import {
  CONFIRM_PASSWORD_VALIDATION,
  EMAIL_VALIDATION,
  NAME_VALIDATION,
  PASSWORD_VALIDATION,
  PHONE_VALIDATION,
  USERNAME_VALIDATION,
} from "../../constants/validationConstants";
import { useValidation } from "../../hooks/useValidation";
import { register } from "../../redux/actions/userActions";
import { ActionAlert } from "../../components/Alert/variants/ActionAlert/ActionAlert";
import { RESET_ERROR } from "../../redux/constants/userConstants";

const RegisterScreen = () => {
  const { loading, userInfo, error } = useSelector(
    (state) => state.userRegister
  );
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [alert, setAlert] = useState(null);
  const [afterRegister, setAfterRegister] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { validateValue } = useValidation();

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    const maxLength = value.length <= 14;

    switch (name) {
      case "name":
        setFullName(value.replace(/[0-9]/g, ""));
        break;
      case "phoneNumber":
        maxLength && setPhoneNumber(value.replace(/\D/g, ""));
        break;
      case "username":
        setUsername(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;

      default:
        break;
    }

    let errs = errors;
    if (errs) delete errs[name];
  };

  const registerData = {
    title: "Daftarkan Diri Anda",
    subtitle: "Silahkan isi form di bawah ini untuk mendaftarkan diri Anda",
    inputTexts: [
      {
        name: "name",
        type: "text",
        label: "Nama lengkap",
        value: fullName,
        placeholder: "Masukkan nama lengkap Anda",
        onChange: onChangeHandler,
        validation: errors && errors.name && errors.name.message,
        message: NAME_VALIDATION.message,
        autocomplete: "off",
        required: true,
      },
      {
        name: "phoneNumber",
        type: "text",
        label: "Nomor Telepon",
        value: phoneNumber,
        placeholder: "Masukkan No. Handphone Anda",
        onChange: onChangeHandler,
        pattern: PHONE_VALIDATION.pattern,
        validation: errors && errors.phoneNumber && errors.phoneNumber.message,
        message: PHONE_VALIDATION.message,
        autocomplete: "off",
        required: true,
      },
      // {
      //   name: "username",
      //   type: "text",
      //   value: username,
      //   placeholder: "Masukkan username",
      //   onChange: onChangeHandler,
      //   validation: errors && errors.username && errors.username.message,
      //   message: USERNAME_VALIDATION.message,
      //   autocomplete: "off",
      //   required: true,
      // },
      {
        name: "email",
        type: "email",
        label: "Email",
        value: email,
        placeholder: "Masukkan email Anda",
        onChange: onChangeHandler,
        pattern: EMAIL_VALIDATION.pattern,
        validation: errors && errors.email && errors.email.message,
        message: EMAIL_VALIDATION.message,
        // required: true,
      },
      {
        name: "password",
        type: "password",
        label: "Kata Sandi",
        value: password,
        placeholder: "Masukkan Kata Sandi",
        onChange: onChangeHandler,
        pattern: PASSWORD_VALIDATION.pattern,
        validation: errors && errors.password && errors.password.message,
        message: PASSWORD_VALIDATION.message,
        required: true,
        mode: "password",
      },
      {
        name: "confirmPassword",
        type: "password",
        label: "Konfirmasi Kata Sandi",
        value: confirmPassword,
        placeholder: "Masukkan Kata Sandi Anda lagi",
        onChange: onChangeHandler,
        validation:
          errors && errors.confirmPassword && errors.confirmPassword.message,
        message: CONFIRM_PASSWORD_VALIDATION.message,
        required: true,
        mode: "password",
      },
    ],
    redirect: `/`,
  };

  const { title, subtitle, inputTexts, redirect } = registerData;

  const checkValidation = (vld, pswd, conPswd) => {
    if (conPswd === pswd) return vld;
    return {
      ...vld,
      confirmPassword: {
        type: "invalid",
        message: CONFIRM_PASSWORD_VALIDATION.message.invalid,
      },
    };
  };

  const submit = (e) => {
    e.preventDefault();
    const validation = checkValidation(
      validateValue(inputTexts),
      password,
      confirmPassword
    );

    if (validation) return setErrors(validation);
    setErrors(null);

    let dataList = {
      fullName,
      username,
      email,
      password,
      phoneNumber,
    };
    dispatch(register(dataList));
  };

  const msgChecker = (value) => {
    if (value === "phone number registered") return "Nomor Telpon Sudah Terdaftar";
    if (value === "username registered") return "Username Sudah Terdaftar";
  };

  useEffect(() => {
    if (!userInfo && afterRegister && error) {
      setAlert({
        variant: "danger",
        title: `Gagal`,
        subtitle: msgChecker(error),
        actions: [
          {
            title: "OK",
            onClick: () => {
              dispatch({ type: RESET_ERROR });
              setAlert(false);
            },
            color: "orange",
          },
        ],
      });
      setAfterRegister(false);
    }
    if (!userInfo) return;
    if (userInfo.Success && afterRegister) {
      setAlert({
        variant: "success",
        title: `Berhasil`,
        subtitle: `Anda berhasil daftarkan akun`,
        actions: [
          {
            title: `Ke halaman Login`,
            onClick: () => navigate("/login"),
            color: "orange",
            variant: "outline",
          },
        ],
      });
      setAfterRegister(false);
    }
    // if (!userInfo.success) navigate(redirect);
  }, [userInfo, navigate, error, afterRegister]);

  return (
    <>
      <AuthLayout title={title} subtitle={subtitle} inputTexts={inputTexts}>
        <Button
          className={`w-full mb-2 mt-3`}
          buttonColor={"primary"}
          buttonLoading={loading}
          onClick={(e) => {
            submit(e);
            setAfterRegister(true);
          }}
        >
          Lanjutkan
        </Button>
        <div className="text-center">
          Sudah punya akun?{" "}
          <Link
            to={"/login"}
            className="font-medium underline link-text text-pari-blue-550"
          >
            Masuk Sekarang
          </Link>
        </div>
      </AuthLayout>
      {alert && <ActionAlert {...alert} />}
    </>
  );
};

export default RegisterScreen;
