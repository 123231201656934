import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from './utils/routes';

function App() {
  
  return (
    <Router>
       <Routes>
         {routes.map((route, idx) => (
           <Route key={idx} path={route.path} element={route.element}/>
         ))}
        </Routes>
    </Router>
  );
}

export default App;
