import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/Button/Button'
import AuthLayout from '../../components/Layout/AuthLayout/AuthLayout'

const SuccessScreen = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const title = queryParams.get('title')
  const subtitle = queryParams.get('subtitle')
  const link = queryParams.get('link')
  const button = queryParams.get('button')

  return (
    <AuthLayout
      title={title}
      subtitle={subtitle}
    >
      <Link to={link}>
        <Button className={'w-full'} buttonColor={'secondary'}>{button}</Button>
      </Link>
    </AuthLayout>
  )
}

export default SuccessScreen