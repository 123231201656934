import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IoChevronBack } from 'react-icons/io5'
import Heading from '../../Heading/Heading'
import './MobileHeader.css'

const MobileHeader = ({wrapperClassName = '', title, onClick}) => {
  const navigate = useNavigate()
  return (
    <div className={`mobile-header ${wrapperClassName}`}>
      <IoChevronBack onClick={onClick ? onClick : () => navigate(-1)}/>
      <Heading
        title={title}
        titleSize={'sm'}
      />
    </div>
  )
}

export default MobileHeader