import Cookies from "js-cookie";
// export const API_BASE_URL = 'https://warehouse.paridev.web.id/api/v1'
// export const API_BASE_URL = 'http://localhost:3000'
export const API_BASE_URL = 'https://api-microwarehouse.pari.co.id/api/v1'
// export const API_BASE_URL = 'https://4c60-206-189-91-51.ngrok-free.app/api/v1'
// export const token = JSON.parse(Cookies.get("userInfo")) || {};
export const LOGIN_PATH = '/login'
export const REGISTER_PATH = '/register'

export const GET = 'GET'
export const POST = 'POST'

export const CONTENT_TYPE_JSON = {'Content-Type': 'application/json'}
