import React from 'react'
import { IoAdd } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import GlobalLayout from '../../components/Layout/GlobalLayout/GlobalLayout'
import AgreementDetail from './AgreementDetail/AgreementDetail'
import AgreementList from './AgreementList/AgreementList'
import AgreementForm from './AgreementForm/AgreementForm.jsx'
import { useWindowDimension } from '../../hooks/useWindowsDimension'

const AgreementScreen = ({agreementType}) => {

  const navigate = useNavigate()
  const {is1024} = useWindowDimension()

  const agreementTypes = [
    {
      type: 'list',
      title: 'Perjanjian',
      element: <AgreementList />,
      action: [
        {
          id: '1',
          title: (
            <>
              <IoAdd/>
              <span>Tambah Perjanjian</span>
            </>
          ),
          color: 'secondary',
          onClick: () => navigate('/agreement/add')
        }
      ],
      download: true,
    },
    {
      type: 'add',
      title: 'Tambah Perjanjian',
      element: <AgreementForm />
    },
    {
      type: 'detail',
      title: 'Detail Perjanjian',
      element: <AgreementDetail />
    },
  ]

  const { title, element, action, download } = agreementTypes.find(x => x.type === agreementType) || agreementTypes[0]

  return (
    <GlobalLayout 
      title={title}
      showSidebar={title !== "Perjanjian" && is1024 ? false : true} 
    >
      {element}
    </GlobalLayout>
  )
}

export default AgreementScreen