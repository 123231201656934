export const useGetDate = () => {
  const makeTwoDigitsDate = (date) => {
    return date < 10 ? `0${date}` : date
  }
  const from = (_date) => {
    const year = _date.getFullYear()
    const month = makeTwoDigitsDate(_date.getMonth()+1)
    const date = makeTwoDigitsDate(_date.getDate())
    const dateToReturn = {
      separated: {
        year: year,
        month: month,
        date: date,
      },
      joined: `${year}${month}${date}`,
      joinedStrip: `${year}-${month}-${date}`
    }
    return dateToReturn
  }
  return {from}
}