import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import AuthLayout from '../../components/Layout/AuthLayout/AuthLayout'
import { EMAIL_VALIDATION } from '../../constants/validationConstants'
import { useValidation } from '../../hooks/useValidation'

const ForgotPasswordScreen = () => {

  const navigation = useNavigate()
  const { validateValue } = useValidation()

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)


  const inputTexts = [
    {
      name:'email',
      type: 'email',
      value: email,
      placeholder:'Email',
      onChange: setEmail,
      pattern: EMAIL_VALIDATION.pattern,
      validation: errors && errors.email && errors.email.message,
      message: EMAIL_VALIDATION.message
    }
  ]

  const submit = (e) => {
    e.preventDefault()
    setErrors(validateValue(inputTexts))
  }
  
  useEffect(() => {
    if(errors !== null) return
    navigation('/success?title=Berhasil&subtitle=Email%20konfirmasi%20sudah%20dikirim%20ke%20email%20Anda&button=Kembali%20ke%20halaman%20Login&link=/login')
  }, [errors, navigation])

  return (
    <AuthLayout
      title={'Lupa Password'}
      subtitle={'Masukan email akun Anda, kami akan mengirimkan email konfirmasi'}
      inputTexts={inputTexts}
    >
      <Button className={`mt-4 w-full`} buttonColor={'secondary'} onClick={submit}>Kirim email konfirmasi</Button>
    </AuthLayout>
  )
}

export default ForgotPasswordScreen